import * as React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Home from "../Components/Pages/Home/Home";
import Body from "../Components/Elements/Home/Body";
import Authentication from "../Requests/UserData";
import Login from "../Components/Pages/Authentication/Login";
import Tunes from "../Components/Pages/Tunes/Tunes";
import MyTunes from "../Components/Pages/MyTunes/MyTunes";
import AddTune from "../Components/Pages/AddTune/AddTune";
import Profile from "../Components/Pages/Profile/Profile";
import EditTune from "../Components/Pages/EditTune/EditTune";
import Links from "../Components/Pages/Links/Links";
const Routers = () => {
  const Authenticated = ({ children }) => {
    const currentUser = Authentication.getCurrentUser();
    if (!currentUser) {
      return <Navigate to="/authentication" replace />;
    }

    return children;
  };
  const UnAuthenticated = ({ children }) => {
    const currentUser = Authentication.getCurrentUser();
    if (currentUser) {
      return <Navigate to="/" replace />;
    }

    return children;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Authenticated>
              <Home>
                 <Body />
              </Home>
            </Authenticated>
          }
        />
                <Route
          exact
          path="/links"
          element={
            <Authenticated>
              <Home>
                <Links />
              </Home>
            </Authenticated>
          }
        />
        <Route
          exact
          path="/common_tunes"
          element={
            <Authenticated>
              <Tunes />
            </Authenticated>
          }
        />

        <Route
          exact
          path="/my_tunes"
          element={
            <Authenticated>
              <MyTunes />
            </Authenticated>
          }
        />
        <Route
          exact
          path="/add_tune"
          element={
            <Authenticated>
              <AddTune />
            </Authenticated>
          }
        />
        <Route
          exact
          path="/edit_tune/:uid"
          element={
            <Authenticated>
              <EditTune />
            </Authenticated>
          }
        />
        <Route
          exact
          path="/profile"
          element={
            <Authenticated>
              <Profile />
            </Authenticated>
          }
        />
      </Routes>

      <Routes>
        <Route exact path="/authentication">
          <Route
            index
            element={
              <UnAuthenticated>
                <Login />
              </UnAuthenticated>
            }
          />
          <Route
            index
            path="/authentication/activate/:uid/:token"
            element={<Login />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
