import * as React from "react";
import TextField from "@mui/material/TextField";

import { styled } from "@mui/material/styles";

import Autocomplete from "@mui/material/Autocomplete";

const Root = styled("div")(
  ({ theme }) => `
  position:relative;
    color: ${
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,0.65)"
        : "rgba(0,0,0,.85)"
    };
    font-size: 14px;
  `
);

export default function Choose({}) {
  return (
    <Root>
      <Autocomplete
        multiple
        id="tags-outlined"
        options={top100Films}
        getOptionLabel={(option) => option.title}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select a person.."
            placeholder="Persons"
          />
        )}
      />
    </Root>
  );
}

const top100Films = [
  { title: "Aaron Schrader" },

  { title: "Ajda None " },

  { title: "Ajda Cvek" },

  { title: "Alfonso Franco vazque" },

  { title: "Ana Lazar" },

  { title: "Angelika Hudler" },
  { title: "Anja Šoštaric" },
  { title: "Barja Drnovšek" },
  { title: "Bojan Cvetreznik" },
  { title: "Chris Haigh" },

  { title: "Christoph Schoenbeck " },

  { title: "Claudia Schwab" },

  { title: "Craig Judelman" },

  { title: "Danijel Bogataj" },

  { title: "dennis peasant" },

  { title: "Domen Keglevic" },

  { title: "Ethno Histeria " },

  { title: "Gabriel Lenoir" },

  { title: "Hermann Fritz" },

  { title: "Kate Young" },

  { title: "Eva Rojko" },
];
