import {Chip, Grid, Typography} from "@mui/material";
import {Container} from "@mui/system";
import React from "react";
import {experimentalStyled as styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import Paper from "@mui/material/Paper";
import Authentication from "../../../Requests/UserData";
import ServerRequests from "../../../Requests/Server";

const Item = styled(Paper)(({theme}) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  minHeight: 100,
}));
const Numbers = () => {
  const currentUser = Authentication.getCurrentUser();
  const [analytics, setAnalytics] = React.useState({
    tunes: 0,
    players: 0,
    countries: 0,
  });
  React.useEffect(() => {
    ServerRequests.post_token("tune/analytics/", currentUser.auth_token)
      .then((response) => response.json())
      .then((result) => {
        setAnalytics(result);
      });
  }, []);

  return (
    <Container>
      <Grid container direction="row" justifyContent="space-between" alignItems="stretch" columnSpacing={1}
            rowSpacing={1}>
        <Grid item md={4} xs={12}>
          <a href="/common_tunes">
            <Item sx={{backgroundColor: "#fffbf7"}}>
              <Typography textAlign="left" fontWeight={800} sx={{mb: 1}}>
                Players
              </Typography>
              <Grid container alignItems="stretch" justifyContent="space-between">
                <SignalCellularAltIcon s={{color: "yellow"}}/>
                <Chip label={analytics?.players + " Players"}/>
              </Grid>
            </Item>
          </a>
        </Grid>
        <Grid item md={4} xs={12}>
          <a href="/common_tunes">
            <Item sx={{backgroundColor: "#f6fbff"}}>
              <Typography textAlign="left" fontWeight={800} sx={{mb: 1}}>
                Tunes
              </Typography>
              <Grid container alignItems="stretch" justifyContent="space-between">
                <SignalCellularAltIcon s={{color: "yellow"}}/>
                <Chip label={analytics?.tunes + " Tunes"}/>
              </Grid>
            </Item>
          </a>
        </Grid>
        <Grid item md={4} xs={12}>
          <a href="/common_tunes">
            <Item sx={{backgroundColor: "#f7fcf8"}}>
              <Typography textAlign="left" fontWeight={800} sx={{mb: 1}}>
                Countries
              </Typography>
              <Grid container alignItems="stretch" justifyContent="space-between">
                <SignalCellularAltIcon s={{color: "yellow"}}/>
                <Chip label={analytics?.countries + " Countries"}/>
              </Grid>
            </Item>
          </a>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Numbers;
