import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Link } from "@mui/material";
import { Box } from "@mui/system";

const Transition = React.forwardRef(function Transition(
  props,
  ref
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Privacy() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Link
        sx={{ mb: 3, display: "block", cursor: "pointer" }}
        color="inherit"
        onClick={handleClickOpen}
      >
        Privacy Policy
      </Link>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", background: "#eee" }}>
          <Toolbar
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "stretch",
            }}
          >
            <Typography sx={{ color: "#000" }} fontWeight={800}>
              FIDDLE GATEWAY DATABASE TERMS OF USE
            </Typography>
            <IconButton
              edge="end"
              sx={{ color: "#000" }}
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ p: 4 }}>
          <div>
            <div
              style={{
                margin: "0cm",
                fontSize: "16px",
                fontFamily: '"Times New Roman",serif',
                border: "none",
              }}
            >
              <ol
                style={{
                  marginBottom: "0cm",
                  listStyleType: "decimal",
                  marginLeft: "26px",
                }}
              >
                <li
                  style={{
                    margin: "0cm",
                    fontSize: "16px",
                    fontFamily: '"Times New Roman",serif',
                    border: "none",
                  }}
                >
                  <span style={{ fontFamily: '"Arial",sans-serif' }}>
                    <span style={{ color: "windowtext" }}>GENERAL</span>
                  </span>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "21px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  FIDDLE GATEWAY DATABASE is a collection of data arranged in a
                  systematic and methodical way and includes information on
                  compositions, melodies, musicians, music teachers, authors,
                  performers and artists (hereinaft
                </span>
              </span>
              <span
                style={{
                  fontSize: "21px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>er: FGD).</span>
              </span>
              <span
                style={{
                  fontSize: "21px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  &nbsp;FGD is accessible to registered
                </span>
              </span>
              <span
                style={{
                  fontSize: "15px",
                  fontFamily: "Helvetica",
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>&nbsp;</span>
              </span>
              <span
                style={{
                  fontSize: "21px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  users online for educational and research purposes in order to
                  enable them to connect with each other and build and share
                  their musical experience and knowledge. FGD is developed,
                  owned and administered by Društvo za Eno glasbo, Poljedelska
                  ulica 6, 1000 Ljubljana, Slovenia (hereinafter ENO).&nbsp;
                </span>
              </span>
              <span
                style={{
                  color: "windowtext",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "20px",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  These Terms of Use apply to all users of the FGD, which is
                  available at https://fiddle-gateway.eu (hereinafter: FGD
                  website) and to all individual parts of this website, such as
                  sections, subpages and others, unless explicitly stated
                  otherwise.&nbsp;
                </span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <br />
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  ENO reserves the right to change these Terms of Use at any
                  time by publishing an amended version on the FGD website along
                  with a notice of the change. Changes to these Terms of Use are
                  binding for all users of the FGD website from the date of
                  publication of the notice of change.
                </span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>&nbsp;</span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "21px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  Most content (especially data, spreadsheets, etc.) of FGD is
                  available to users free of charge and without restrictions.
                  Access to or use of some content may be subject to special
                  conditions. Users are requested to read all visible tags and
                  check under what terms and conditions they are allowed to
                  access the content and how (in what way and to what extent)
                  they are allowed to use the content, before any use of the
                  content available on the FGD website.
                </span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <br />
            </p>
            <div
              style={{
                margin: "0cm",
                fontSize: "16px",
                fontFamily: '"Times New Roman",serif',
                border: "none",
              }}
            >
              <ol
                style={{
                  marginBottom: "0cm",
                  listStyleType: "decimal",
                  marginLeft: "26px",
                }}
              >
                <li
                  style={{
                    margin: "0cm",
                    fontSize: "16px",
                    fontFamily: '"Times New Roman",serif',
                    border: "none",
                  }}
                >
                  <span style={{ fontFamily: '"Arial",sans-serif' }}>
                    <span style={{ color: "windowtext" }}>USERS</span>
                  </span>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>&nbsp;</span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  Any person who registers online&nbsp;is considered a user.
                </span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  Content published on the FGD website can be accessed by users
                  free of charge and used by viewing, printing or downloading it
                  in any way on their personal computer, provided that it is
                  exclusively for private and non-commercial use.
                </span>
              </span>
              <span
                style={{
                  color: "windowtext",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "20px",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  Without an express written permission of ENO or other
                  copyright holders, performer
                </span>
              </span>
              <span
                dir="RTL"
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial Unicode MS",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>’</span>
              </span>
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  s right holders or other rights holders, any other use of
                  content published or otherwise made available/accessible to
                  the public on the FGD website (https://fiddle-gateway.eu) or
                  on any individual web (sub)page of this website is prohibited.
                  Users of the FGD website are not allowed to publicly
                  reproduce, publicly distribute, publicly transmit, publicly
                  broadcast, publicly display or publicly communicate
                  copyrighted works or other protected content published on the
                  FGD website. Nor is it legal to use such content for the
                  purpose of advertising or achieving any other direct or
                  indirect economic benefit.
                </span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>&nbsp;</span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  Before any type of use that is not exclusively private and
                  non-commercial, users must verify whether such use is
                  permitted and under what conditions (i.e. use for economic
                  gain, public reproduction, public distribution, public
                  display, etc.). Users must always pre-check the content of
                  copyright and other rights notices (such as copyright-related
                  rights, privacy rights or personal rights) and obtain
                  additional clarification or any necessary permission(s) from
                  authors or other copyright holders to use any copyrighted
                  content.
                </span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  color: "windowtext",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "20px",
                }}
              >
                &nbsp;
              </span>
            </p>
            <div
              style={{
                margin: "0cm",
                fontSize: "16px",
                fontFamily: '"Times New Roman",serif',
                border: "none",
              }}
            >
              <ol
                style={{
                  marginBottom: "0cm",
                  listStyleType: "decimal",
                  marginLeft: "26px",
                }}
              >
                <li
                  style={{
                    margin: "0cm",
                    fontSize: "16px",
                    fontFamily: '"Times New Roman",serif',
                    border: "none",
                  }}
                >
                  <span style={{ fontFamily: '"Arial",sans-serif' }}>
                    <span style={{ color: "windowtext" }}>
                      LIMITATION OF LIABILITY
                    </span>
                  </span>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>&nbsp;</span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  As the administrator of the FGD website, ENO endeavours to
                  ensure that the information published or otherwise accessible
                  on the website is accurate, up-to-date and regularly updated
                  and/or checked. ENO is not responsible for the authenticity,
                  correctness or verification of data and any other content that
                  users can access through the FGD website.
                </span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>&nbsp;</span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  The FGD website also publishes links to third-party websites,
                  which ENO has no influence or control over. ENO is not
                  responsible for the operation of third-party websites or the
                  legality and accuracy of the content published there.
                </span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>&nbsp;</span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  ENO endeavours to ensure smooth and error-free operation of
                  the FGD website and to ensure the greatest possible
                  availability and accessibility of the website, but is not
                  responsible for any delays in updating any published content.
                  ENO is also not liable for any damage that may occur to users
                  as a result of regular technical maintenance, e.g. due to
                  temporary errors in operation of the website or in the event
                  the service is made unavailable. ENO shall strive to eliminate
                  all errors in a timely manner or as soon as possible.
                </span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>&nbsp;</span>
              </span>
              <span
                style={{
                  color: "windowtext",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "20px",
                }}
              >
                &nbsp;
              </span>
            </p>
            <div
              style={{
                margin: "0cm",
                fontSize: "16px",
                fontFamily: '"Times New Roman",serif',
                border: "none",
              }}
            >
              <ol
                style={{
                  marginBottom: "0cm",
                  listStyleType: "decimal",
                  marginLeft: "26px",
                }}
              >
                <li
                  style={{
                    margin: "0cm",
                    fontSize: "16px",
                    fontFamily: '"Times New Roman",serif',
                    border: "none",
                  }}
                >
                  <span style={{ fontFamily: '"Arial",sans-serif' }}>
                    <span style={{ color: "windowtext" }}>
                      PRIVACY POLICY&nbsp;
                    </span>
                  </span>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>&nbsp;</span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  ENO ensures the protection of all personal data in accordance
                  with the requirements of applicable regulations on personal
                  data protection, including GDPR.
                </span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>&nbsp;</span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  ENO processes and stores only those personal data which users
                  voluntarily enter in the online form(s) provided on the FGD
                  website. By entering data in the provided form(s) the user
                  gives their explicit consent for data processing.&nbsp;
                </span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>&nbsp;</span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  By doing so, the user allows ENO to process and store their
                  personal data including: name, surname, e-mail address,
                  personal repertoire, relations to the students and teachers
                  and to send them notifications about implemented changes and
                  newsletters from time to time or regularly to the e-mail
                  address specified. The user who submits their personal data to
                  ENO via any online form, agrees and allows ENO to use their
                  personal data for the purpose of providing all FGD users
                  information about compositions, melodies, musicians, music
                  teachers, authors, performers and artist and enable them to
                  connect with each other.&nbsp;
                </span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>&nbsp;</span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  The user is informed and agrees that ENO keeps the data
                  provided by the user via the online contact form until the
                  user declares or indicates that they no longer wish to receive
                  such e-mails. Other data may be kept by ENO for as long as is
                  necessary to achieve the purpose for which the data was
                  collected. After such purpose has been achieved, the data
                  shall be permanently deleted or effectively anonymised so that
                  the stored data can no longer be linked to any specific user.
                </span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>&nbsp;</span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  To protect the transfer and storage of personal data ENO uses
                  all necessary technological and organisational means (such as
                  physical and software protection of premises and information
                  equipment, regular updates and maintenance of hardware and
                  software and a controlled system of usernames and passwords)
                  to prevent unauthorised use, access, destruction, alteration,
                  public disclosure or other unauthorised processing of personal
                  data and ensure adequate protection of rights of data
                  subjects.
                </span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>&nbsp;</span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  ENO will process personal data only to the extent necessary to
                  achieve the purposes of processing and as long as this is
                  necessary to achieve the pursued goal; if the data was
                  obtained on the basis of user consent, for the purpose for
                  which consent was given, the data will be stored until such
                  consent is withdrawn. In no case will ENO send or otherwise
                  disclose personal data it has obtained to third parties,
                  except for its subcontractors or partners (data processors)
                  who provide services necessary for the operation of the FGD.
                  ENO has signed appropriate contracts with subcontractors or
                  partners (data processors) on data processing and storage,
                  which oblige them to implement appropriate technical and
                  organisational measures to ensure the required level of
                  protection of all personal data processed or stored for ENO.
                </span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>&nbsp;</span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  The data subject has the right to revoke their consent at any
                  time. Furthermore the data subject has the right to review,
                  correct, transfer, delete or request the restriction of the
                  processing of their personal data. An individual may at any
                  time exercise these rights by sending their request to ENO
                </span>
              </span>
              <span
                dir="RTL"
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial Unicode MS",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>’</span>
              </span>
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  s data protection officer who can be reached by e-mail at
                  drustvo@enoglasba.info or in writing at:&nbsp;
                </span>
              </span>
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>Dru</span>
              </span>
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  štvo za Eno glasbo, Poljedelska ulica 6,&nbsp;1000 Ljubljana,
                  Slovenia, where the individual may also ask for more
                  information on the protection and processing of personal data.
                </span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
              }}
            >
              <span
                style={{
                  fontSize: "21px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>&nbsp;</span>
              </span>
            </p>
            <div
              style={{
                margin: "0cm",
                fontSize: "16px",
                fontFamily: '"Times New Roman",serif',
                border: "none",
              }}
            >
              <ol
                style={{
                  marginBottom: "0cm",
                  listStyleType: "decimal",
                  marginLeft: "26px",
                }}
              >
                <li
                  style={{
                    margin: "0cm",
                    fontSize: "16px",
                    fontFamily: '"Times New Roman",serif',
                    border: "none",
                  }}
                >
                  <span style={{ fontFamily: '"Arial",sans-serif' }}>
                    <span style={{ color: "windowtext" }}>COOKIES</span>
                  </span>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>&nbsp;</span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  ENO uses web cookies (hereinafter: cookies) on the FGD
                  website&nbsp;(https:fiddle-gateway.eu). Cookies are files that
                  are stored on the user's device used to access the website.
                  When users revisit the website, the data collected and
                  obtained by means of a cookie during the previous visit can be
                  read. Cookies on the website do not allow the identification
                  of any individual and it is not possible to determine which
                  specific user they are. With the help of cookies ENO obtains
                  information on how users use the website, especially on the
                  frequency of visiting the website, on the most accessed
                  content, on the average time of the visit, etc. (web
                  analytics, analytics cookies are anonymised).
                </span>
              </span>
              <span
                style={{
                  color: "windowtext",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "20px",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  More information on individual cookies, the purposes of
                  processing the collected data and the duration of storage of
                  specific cookies is listed&nbsp;
                </span>
              </span>
              <span
                style={{
                  fontSize: "19px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>below:</span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "19px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>&nbsp;</span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "19px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  &nbsp; &nbsp; • LIST OF COOKIES
                </span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <br />
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  By giving consent to the installation of cookies, the user
                  agrees to the storage of cookies and access to cookies (or
                  data obtained from cookies) that are already stored on the
                  user's device. The user can also decide whether and which
                  cookies operators or providers can install on their device by
                  using their web browser settings (i.e. "do not track
                  request").
                </span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <br />
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  In cases where cookies are necessary to provide a specific
                  service, for troubleshooting or to ensure operation,
                  un-submitted or withdrawn consent to the installation of
                  cookies may prevent the administrator to provide the service
                  or adversely influence optimal operation of the service or
                  specific parts of the service.
                </span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "14.95pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
              }}
            >
              <strong>
                <span
                  style={{
                    fontSize: "24px",
                    fontFamily: "Helvetica",
                    color: "black",
                    background: "white",
                  }}
                >
                  <span style={{ color: "windowtext" }}>&nbsp;</span>
                </span>
              </strong>
            </p>
            <div
              style={{
                margin: "0cm",
                fontSize: "16px",
                fontFamily: '"Times New Roman",serif',
                border: "none",
              }}
            >
              <ol
                style={{
                  marginBottom: "0cm",
                  listStyleType: "decimal",
                  marginLeft: "26px",
                }}
              >
                <li
                  style={{
                    margin: "0cm",
                    fontSize: "16px",
                    fontFamily: '"Times New Roman",serif',
                    border: "none",
                  }}
                >
                  <span style={{ fontFamily: '"Arial",sans-serif' }}>
                    <span style={{ color: "windowtext" }}>
                      INTELLECTUAL PROPERTY RIGHTS
                    </span>
                  </span>
                  <span
                    style={{
                      fontFamily: '"Arial",sans-serif',
                      fontSize: "16.0pt",
                      color: "black",
                    }}
                  >
                    <span style={{ color: "windowtext" }}>&nbsp;</span>
                  </span>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "21px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>&nbsp;</span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "21px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  The contents of the FGD website and the contents of each
                  individual web page of the FGD website may be protected as
                  copyrighted works or as database in accordance with the
                  provisions of relevant legislation (i.e. Copyright and Related
                  Rights Act, Official Gazette of the Republic of Slovenia No.
                  21/95 and subsequent changes) or as other objects of
                  protection by other intellectual property rights (trademarks,
                  design and similar).&nbsp;
                </span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "21px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>&nbsp;</span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "21px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  ENO strives to the greatest extent possible to respect
                  copyright and other intellectual property rights and therefore
                  states the source and authorship of the work or ownership of
                  the rights with each publication, if this is stated in the
                  source from which the content is received/obtained.&nbsp;
                </span>
              </span>
              <span
                style={{
                  color: "windowtext",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "21px",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "21px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  In the event that the ENO, despite its careful efforts, failed
                  to find all the authors or rights holders, any rights holders
                  of published works are kindly requested to contact ENO
                  at&nbsp;contact@fiddle-gateway.eu so that the appropriate
                  license for the use of their work on the FGD website can be
                  arranged. Also please let us know at the same address if you
                  notice that an individual citation of authorship or source is
                  deficient or incorrect.
                </span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>&nbsp;</span>
              </span>
            </p>
            <div
              style={{
                margin: "0cm",
                fontSize: "16px",
                fontFamily: '"Times New Roman",serif',
                border: "none",
              }}
            >
              <ol
                style={{
                  marginBottom: "0cm",
                  listStyleType: "decimal",
                  marginLeft: "26px",
                }}
              >
                <li
                  style={{
                    margin: "0cm",
                    fontSize: "16px",
                    fontFamily: '"Times New Roman",serif',
                    border: "none",
                  }}
                >
                  <span style={{ fontFamily: '"Arial",sans-serif' }}>
                    <span style={{ color: "windowtext" }}>
                      APPLICABLE LAW AND JURISDICTION
                    </span>
                  </span>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>&nbsp;</span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "16.0pt",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: "16px",
                fontFamily: '"Helvetica Neue"',
                color: "black",
                border: "none",
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  background: "white",
                }}
              >
                <span style={{ color: "windowtext" }}>
                  These Terms of Use applicable for FGD website shall be
                  governed by the laws of the Republic of Slovenia and any
                  disputes that may arise between users of the FGD website and
                  ENO. In the event that any provision of the Terms of Use is
                  found to be invalid, all other provisions shall remain in
                  force. ENO will strive to resolve all possible disputes with
                  users amicably. If such a dispute cannot be resolved amicably
                  within a reasonable timeframe, the District Court in Ljubljana
                  shall have jurisdiction to resolve the dispute.
                </span>
              </span>
            </p>
          </div>
        </Box>
      </Dialog>
    </div>
  );
}
