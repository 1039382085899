import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
const View = ({ titles, setTitles }) => {
  const handleDelete = (id) => {
    setTitles((prev) => prev.filter((item) => item.id !== id));
  };
  return (
    <List sx={{ width: "100%", bgcolor: "#eee" }}>
      {titles.map((title) => (
        <React.Fragment key={title?.id}>
          <ListItem alignItems="flex-start">
            <ListItemText primary={title?.title} />

            <IconButton
              aria-label="Example"
              onClick={(e) => handleDelete(title.id)}
            >
              <DeleteForeverIcon sx={{ color: "red" }} />
            </IconButton>
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
};

export default View;
