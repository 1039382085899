import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Autocomplete,
  Button,
  Container,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import ServerRequests from "../../../../../Requests/Server";
import Authentication from "../../../../../Requests/UserData";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";

import BlurOffIcon from "@mui/icons-material/BlurOff";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const Tonality = ({ tonality, setTonality, modu, setModu }) => {
  const currentUser = Authentication.getCurrentUser();
  const [tonalities, setTonalities] = React.useState(null);
  const [modus, setModus] = React.useState([]);
  const [totus, setTotus] = React.useState(null);
  const [counter, setCounter] = React.useState(0);

  React.useEffect(() => {
    ServerRequests.get_token("home/tonalities/", currentUser.auth_token).then(
      (result) => setTonalities(result)
    );
    ServerRequests.get_token("home/modus/", currentUser.auth_token).then(
      (result) => setModus(result)
    );
  }, [modu]);
  const handleDelete = (id) => {
    setTonality((prev) => prev.filter((item) => item.id !== id));
  };

  const handleAdd = () => {
    if (totus !== null && modu != null) {
      setTonality((prev) => [
        ...prev,
        { id: counter, name: totus?.name, modus: modu?.name },
      ]);
      setCounter((prev) => prev + 1);
    }
    // setModu(null);
    // setTotus(null);
  };
  return (
    <Container>
      <Grid container columnSpacing={2}>
        <Grid item md={6}>
          <Grid container columnSpacing={2}>
            <Grid item md={6}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={tonalities}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }

                  return option.name;
                }}
                onChange={(event, newValue) => {
                  setTotus(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Tonalities" />
                )}
              />
              {/* <Autocomplete
                multiple
                id="tags-filled"
                limitTags={2}
                options={tonalities || []}
                getOptionLabel={(option) => {
                  // e.g value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }

                  return option.name;
                }}
                onChange={(event, newValue) => {
                  setTonality(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="Tonality"
                    placeholder="Options..."
                  />
                )}
              /> */}
            </Grid>
            <Grid item md={6}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={modus}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }

                  return option.name;
                }}
                onChange={(event, newValue) => {
                  setModu(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Modes" placeholder="Select" />
                )}
              />
              {/* <Autocomplete
                multiple
                id="tags-filled"
                limitTags={2}
                options={modus || []}
                getOptionLabel={(option) => {
                  // e.g value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }

                  return option.name;
                }}
                onChange={(event, newValue) => {
                  console.log(newValue);
                  setModu(newValue);
                }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="Modus"
                    placeholder="Options..."
                  />
                )}
              /> */}
            </Grid>
            <Grid item md={12}>
              <Button
                variant="contained"
                onClick={handleAdd}
                sx={{
                  width: "100%",
                  mt: 2,
                }}
              >
                Add Tonality/Mode
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={6}>
          <Typography sx={{ fontWeight: 900 }}>
            Tonality / Modes Preview
          </Typography>
          <Divider />
          <List sx={{ width: "100%", bgcolor: "#eee" }}>
            {tonality?.map((item, key) => (
              <>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    secondary={
                      <React.Fragment>
                        <IconButton
                          aria-label="Example"
                          onClick={(e) => handleDelete(item.id)}
                        >
                          <DeleteForeverIcon sx={{ color: "red" }} />
                        </IconButton>
                        {item?.name + " -- " + item?.modus}
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </>
            ))}
          </List>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Tonality;
