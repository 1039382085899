import { CssBaseline } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Header from "../../Base/Header/Header";
import Sidebar from "../../Base/Sidebar/Sidebar";
import Forms from "../../Elements/EditTune/Forms";
import { useParams, useNavigate } from "react-router-dom";
import ServerRequests from "../../../Requests/Server";
import Authentication from "../../../Requests/UserData";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  borderRadius: 20,
}));

const Main = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: "center",
  color: theme.palette.text.secondary,
  backgroundColor: "#eee",
}));
const EditTune = () => {
  const currentUser = Authentication.getCurrentUser();
  let { uid } = useParams();
  const [tune, setTune] = React.useState(null);
  const [profile, setProfile] = React.useState(null);
  React.useEffect(() => {
    ServerRequests.get_token(
      "tune/edit_tune/" + uid + "/",
      currentUser.auth_token
    ).then((response) => setTune(response));
    ServerRequests.get_token(
      "authentication/profile/",
      currentUser.auth_token
    ).then((response) => setProfile(response));
  }, []);
  console.log(tune);
  return (
    <CssBaseline>
      <Container
        sx={{
          minHeight: "100vh",
          backgroundColor: "#eee",
        }}
        maxWidth={false}
        disableGutters
      >
        <Header />

        <Container maxWidth="xl" sx={{ flexGrow: 1, mt: 2 }}>
          <Grid container flexDirection="row" spacing={2}>
            <Grid
              item
              xs={false}
              md={2}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              <Sidebar />
            </Grid>
            <Grid item xs={12} md={10}>
              <Main sx={{ mb: 10 }}>
                <Container component={Item} sx={{ position: "relative" }}>
                  {tune !== null && <Forms tune={tune} profile={profile} />}
                </Container>
              </Main>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </CssBaseline>
  );
};

export default EditTune;
