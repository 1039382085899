import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Container } from "@mui/system";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
const columns = [
  { id: "title", label: "Titles", used: "title" },
  { id: "type", label: "Types", used: "details" },
  { id: "country", label: "Countries", used: "country" },
  { id: "region", label: "Regions", used: "details" },
  { id: "material", label: "Materials", used: "material" },
  { id: "meter", label: "Meters", used: "details" },
  { id: "bpm", label: "BPM", used: "details" },
  { id: "level", label: "Level", used: "details" },
  { id: "tonality", label: "Tonalities", used: "details" },
  { id: "author", label: "Authors", used: "details" },
  { id: "technique", label: "Techniques", used: "details" },
];
const Transition = React.forwardRef(function Transition(
  props,
  ref
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function OneTune({ tune }) {
  const [open, setOpen] = React.useState(false);
  // console.log("TUNE:, ", tune)
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <IconButton
        sx={{ color: "#000" }}
        aria-label="add to shopping cart"
        size="small"
        onClick={handleClickOpen}
      >
        <RemoveRedEyeIcon fontSize="inherit" />
      </IconButton>
      <Dialog
        fullScreen
        sx={{ p: { md: 10, xs: 5 } }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", background: "#eee" }}>
          <Toolbar
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "stretch",
            }}
          >
            <Typography sx={{ color: "#000" }} fontWeight={800}>
              Tune details
            </Typography>
            <IconButton
              edge="end"
              sx={{ color: "#000" }}
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ p: 4 }}>
          <Container
            sx={{
              minWidth: "100%",
            }}
          >
            <Grid container>
              {columns.map((item, keyIdx) => (
                <Grid
                  item
                  sx={{
                    mb: 2,
                    pb: 2,
                    borderBottom: "1px solid #eee",
                  }}
                  md={4}
                  sm={6}
                  xs={12}
                  key={keyIdx}
                >
                  <Grid item xs={12}>
                    <Typography fontWeight={800} variant="h6">
                      {item.label}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {item?.used === "details" ? (
                      <>
                        {tune?.[item?.id]?.map((v, k) => (
                          <Typography variant="body1" key={k}>
                            {v?.[item?.id + "_" + item?.used]?.name}
                            {item?.id === "tonality" && v?.modus_details &&
                              "-" + v?.modus_details?.name}
                          </Typography>
                        ))}
                      </>
                    ) : (
                      <>
                        {item?.used === "material" ? (
                          <>
                            {tune?.[item?.id]?.map((v, k) => (
                              <Box
                                sx={{
                                  width: "100%",
                                  background: "#eee",
                                  p: 1,
                                  mb: 1,
                                }}
                                key={k}
                              >
                                <Typography fontWeight={800}>
                                  <Link
                                    href={v?.link}
                                    target="_blank"
                                    sx={{
                                      textDecoration: "none",
                                      color: "#747474",
                                    }}
                                  >
                                    {v?.name}
                                  </Link>
                                </Typography>
                                <Typography fontWeight={800}>
                                  {v?.type_details?.type} -{" "}
                                  <Link
                                    href={v?.link}
                                    target="_blank"
                                    sx={{
                                      textDecoration: "none",
                                      color: "#0384fc",
                                    }}
                                  >
                                    link
                                  </Link>
                                </Typography>
                              </Box>
                            ))}
                          </>
                        ) : (
                          <>
                            {tune?.[item?.id]?.map((v, k) => (
                              <Typography variant="body1" key={k}>
                                {v?.[item?.used]?.name !== undefined
                                  ? v?.[item?.used]?.name
                                  : v?.[item?.used]}{" "}
                              </Typography>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      </Dialog>
    </div>
  );
}
