import * as React from "react";

import TextField from "@mui/material/TextField";

import FormControl from "@mui/material/FormControl";

const Techniques = () => {
  return (
    <FormControl fullWidth>
      <TextField id="outlined-basic" label="Technique" variant="outlined" />
    </FormControl>
  );
};

export default Techniques;
