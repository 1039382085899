import {CssBaseline} from "@mui/material";
import {Box, Container} from "@mui/system";
import React from "react";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Header from "../../Base/Header/Header";
import Sidebar from "../../Base/Sidebar/Sidebar";
import Informations from "../../Elements/Profile/Informations";
import ServerRequests from "../../../Requests/Server";
import Authentication from "../../../Requests/UserData";

const Item = styled(Paper)(({theme}) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Main = styled(Box)(({theme}) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  backgroundColor: "#eee",
}));
const Profile = () => {
  const currentUser = Authentication.getCurrentUser();
  const [profile, setProfile] = React.useState(null);
  React.useEffect(() => {
    ServerRequests.get_token(
      "authentication/profile/",
      currentUser.auth_token
    ).then((result) => setProfile(result));
  }, []);
  return (
    <CssBaseline>
      <Container
        sx={{
          minHeight: "100vh",
          backgroundColor: "#eee",
        }}
        maxWidth={false}
        disableGutters
      >
        <Header/>

        <Container maxWidth="xl" sx={{flexGrow: 1, mt: 2}}>
          <Grid container flexDirection="row" spacing={2}>
            <Grid
              item
              xs={false}
              md={2}
              sx={{
                display: {xs: "none", md: "block"},
              }}
            >
              <Sidebar/>
            </Grid>
            <Grid item xs={12} md={10}>
              <Main>
                <Grid container columnSpacing={2} rowSpacing={2}>
                  <Grid item xs={12} md={4}>
                    <Item>
                      <Informations profile={profile}/>
                    </Item>
                  </Grid>

                  {/*<Grid item xs={12} md={8}>*/}
                  {/*  <Item sx={{ minHeight: "80vh" }}>*/}
                  {/*    <History />*/}
                  {/*  </Item>*/}
                  {/*</Grid>*/}
                </Grid>
              </Main>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </CssBaseline>
  );
};

export default Profile;
