import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import ServerRequests from "../../../../../Requests/Server";
import Authentication from "../../../../../Requests/UserData";

const Level = ({ level, setLevel }) => {
  const currentUser = Authentication.getCurrentUser();
  const [levels, setLevels] = React.useState(null);
  React.useEffect(() => {
    ServerRequests.get_token("home/levels/", currentUser.auth_token).then(
      (result) => setLevels(result)
    );
  }, []);

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Level</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={level}
        label="Level"
        onChange={(e) => setLevel(e.target.value)}
      >
        {levels?.map((item) => (
          <MenuItem value={item?.id}>Level : {item?.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Level;
