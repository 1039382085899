import React from "react";
import { Autocomplete, Chip, InputLabel, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { createFilterOptions } from "@mui/material/Autocomplete";
import ServerRequests from "../../../../../Requests/Server";
import Authentication from "../../../../../Requests/UserData";
import { is } from "date-fns/locale";

const filter = createFilterOptions();

const Types = ({ types, setTypes, errors, profile, tune }) => {
  const currentUser = Authentication.getCurrentUser();

  const [type, setType] = React.useState(null);
  React.useEffect(() => {
    ServerRequests.get_token("home/types/", currentUser.auth_token).then(
      (result) => setType(result)
    );
  }, []);
  const handleTypeDelete = (uid) => {
    ServerRequests.delete_token(
      `tune/delete/type/${uid}/${tune}/`,
      currentUser.auth_token
    ).then((response) => console.log(response));
  };
  return (
    <Box sx={{ m: 1, width: "100%" }}>
      <Autocomplete
        multiple
        id="tags-filled"
        options={type || []}
        disableClearable={true}
        defaultValue={types}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }

          return option.name;
        }}
        onChange={(event, newValue, reason, detail) => {
          if (reason === "removeOption") {
            if (detail.option.uid !== undefined) {
              console.log(detail.option);
              handleTypeDelete(detail.option.uid);
            }
          }
          const finalValue = newValue.map((value) => {
            if (typeof value === "string") {
              return {inputValue: value, name: value};
            }
            return value;
          });
          const finalvalUnique = finalValue.filter(
            (v, i, a) => a.findIndex((t) => t.name === v.name) === i
          );
          setTypes(finalvalUnique);
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
  
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue.toLowerCase() === option.name.toLowerCase());
          const isExisting2 = types.some((option) => inputValue.toLowerCase() === option.name.toLowerCase());
          if (inputValue !== '' && !isExisting && !isExisting2) {
            filtered.push({
              inputValue,
              name: inputValue,
            });
          }
  
          return filtered;
        }}
        clearOnBlur
        freeSolo
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.name || option}
              {...getTagProps({ index })}
              disabled={
                (option?.editor !== undefined) &
                (option?.editor !== profile?.id)
              }
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            label="Tune Types"
            error={errors?.types ? true : false}
            helperText={errors?.types}
            placeholder="Click on the option..."
          />
        )}
      />
    </Box>
  );
};

export default Types;
