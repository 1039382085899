import * as React from "react";
import TextField from "@mui/material/TextField";

import { styled } from "@mui/material/styles";

import Autocomplete from "@mui/material/Autocomplete";
import ServerRequests from "../../../Requests/Server";
import Authentication from "../../../Requests/UserData";

const Root = styled("div")(
  ({ theme }) => `
  position:relative;
    color: ${
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,0.65)"
        : "rgba(0,0,0,.85)"
    };
    font-size: 14px;
  `
);

export default function Choose({
  disablechoose,
  setDisablechoose,
  setFilters,
  setUpdated,
  filters,
}) {
  const currentUsers = filters['users'] || []
  console.log('current users: ', currentUsers)
  const [users, setUsers] = React.useState([]);
  React.useEffect(() => {
    const currentUser = Authentication.getCurrentUser();
    ServerRequests.get_token("home/users/all/", currentUser.auth_token).then(
      (result) => {
        // get rid of duplicatees in the array
        const unique = result.filter(
          (v, i, a) =>
            a.findIndex(
              (t) =>
                `${t.first_name} ${t.last_name}` ===
                `${v.first_name} ${v.last_name}`
            ) === i
        );
        setUsers(unique);
      }
    );
  }, [filters]);
  return (
    <Root>
      <Autocomplete
        multiple
        id="tags-outlined"
        limitTags={2}
        disabled={disablechoose}
        options={users}
        value={currentUsers}
        onChange={(event, newValue) => {
          if (newValue.length > 0) {
            setFilters((prev) => ({ ...prev, users: newValue }));
          } else {
            setFilters((prev) => {
              const copy = { ...prev };
              delete copy["users"];
              return copy;
            });
          }
          setFilters((prev) => ({
            ...prev,
            next: false,
          }));

          setUpdated((prev) => prev + 1);
        }}
        getOptionLabel={(option) => option.first_name + " " + option.last_name}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select a person.."
            placeholder="Persons"
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                return e.target.value;
              }
            }}
          />
        )}
      />
    </Root>
  );
}
