import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Container } from "@mui/system";
import { Box, Divider, Grid, Link, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ServerRequests from "../../../../../Requests/Server";
import Authentication from "../../../../../Requests/UserData";
import Autocomplete from "@mui/material/Autocomplete";
import Types from "./Types";
import Date from "./Date";
import Invitation from "./Invitation";
import Chip from "@mui/material/Chip";

export default function Authors({
  errors,
  setDate,
  date,
  setStore,
  store,
  titles,
}) {
  const currentUser = Authentication.getCurrentUser();
  const [open, setOpen] = React.useState(false);
  const [extend, setExtend] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [counter, setCounter] = React.useState(0);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setExtend(false);
  };
  const handleSave = () => {
    ServerRequests.post_token(
      "home/learned_from/",
      currentUser.auth_token,
      store
    ).then((result) => {
      if (result.ok) {
        setOpen(false);
      }
    });
  };

  const fetchUsers = (inputValue) => {
    if (inputValue) {
      ServerRequests.post_token("home/users_fetch/", currentUser.auth_token, {
        word: inputValue,
      })
      .then((result) => result.json())
      .then((response) => {
        setUsers(response);
      });
    } else {
      setUsers([]);
    }
  };
  // console.log(store);
  const handleDelete = (id) => {
    setStore((prev) => prev.filter((item) => item.id !== id));
  };
  return (
    <Container
      sx={{
        border: "1px solid #ddd",
        p: 1,
        m: 1,
      }}
    >
      {/* <Date date={date} setDate={setDate} errors={errors} /> */}
      {extend === false ? (
        <>
          <Grid container columnSpacing={2}>
            <Grid item md={12} sx={{ mt: 2, mb: 2, ml: 1, width: "100%" }}>
              <Autocomplete
                disablePortal
                fullWidth
                sx={{
                  width: "100%",
                }}
                id="combo-box-demo"
                inputValue={user}
                value={user}
                onChange={(event, newValue, reason) => {
                  if (reason !== "clear") {
                    const isFound = store.some((element) => {
                      return element.id === newValue?.id;
                    });

                    if (isFound === false) {
                                            setStore((prev) => [...prev, newValue]);
                    }
                  }
                  if (reason === "selectOption") {
                    setUser("");
                    setUsers([]);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  if (newInputValue) {
                    fetchUsers(newInputValue);
                    setUser(newInputValue);
                  } else {
                    setUser("");
                    setUsers([]);
                  }
                }}
                options={users}
                noOptionsText={
                  user?.length > 2 && (
                    <Typography>
                      If you couldn't found it in our database click to:
                      <Button
                        onClick={() => {
                          setExtend((prev) => true);
                          setUser(null);
                          // setStore({});
                        }}
                      >
                        Add new
                      </Button>
                    </Typography>
                  )
                }
                renderInput={(params) => (
                  <TextField {...params} label="Author name" />
                )}
              />
              <Box sx={{ mt: 2 }}>
                {store?.map((item) => (
                  <Chip
                    label={
                      item?.label || item?.first_name + " " + item?.last_name
                    }
                    sx={{ mr: 1 }}
                    onDelete={() => handleDelete(item?.id)}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="First name"
                onInput={(e) =>
                  setUser({ ...user, first_name: e.target.value })
                }
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="name"
                label="Last name"
                onInput={(e) => setUser({ ...user, last_name: e.target.value })}
                fullWidth
                variant="standard"
              />
            </Grid>
            <Typography sx={{ p: 2, mt: 2 }}>
              If you have an email address of the person who you believe
              composed this tune, you can invite her/him to the community of
              fiddle tunes, educators and players! <br />
              Thank you for spreading the idea!
              <br />
              <Invitation
                store={store}
                setExtend={setExtend}
                titles={titles}
                setUser={setUser}
                user={user}
                setStore={setStore}
                setCounter={setCounter}
                counter={counter}
              />
            </Typography>
          </Grid>
        </>
      )}
    </Container>
  );
}
