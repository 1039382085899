import {Container, Grid, Typography} from "@mui/material";
import {Box} from "@mui/system";
import React from "react";
import Link from '@mui/material/Link';

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const Links = () => {
  return (
    <Box>
      <Container>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          textAlign="left"
        >
          <Grid item xs={12} md={12} sx={{mt: 4}}>
            <Typography variant="h5">Check out the important links!</Typography>
            <ul>
              <li>
                <Typography variant="h6">
                  2 Erasmus+ conferences organised by {" "}
                  <Link href="https://bahnhof-leisnig.de/">
                    Bahnhof Leisnig
                  </Link>
                  (GER) & {" "}
                  <Link href="www.enoglasba.info/projekti/fiddle-gateway/">
                    Društvo za ENO glasbo / Society for ONE music (SLO):
                  </Link>
                </Typography>
                <ul>
                  <li>
                    European Fiddle Gate conference, August 21st – 28th 2022, Bahnhof Leisnig{" "}
                    <Link
                      target="_blank"
                      href="https://www.dropbox.com/scl/fi/m9sif0670s1zl1gkhifi1/Presentation_conference2022.pdf?rlkey=lamn6l653884a3ej8vno8tnj4&dl=0">PDF</Link>,{" "}
                    <Link
                      target="_blank"
                      href="https://www.dropbox.com/scl/fi/hedbony6sxpgsq6aozqto/International-Fiddle-Gateway-Conference-2022-Christoph-Schoenbeck.mp4?rlkey=qcan9yml000opq943xl3bt6fh&dl=0">Video</Link>
                  </li>
                  <li>
                    European Fiddle Gate conference, August 13th – 20th 2023, Bahnhof Leisnig{" "}
                    <Link
                      target="_blank"
                      href="https://www.dropbox.com/scl/fi/tg20l2i9t1sq5il6ccjf7/Presentation_conference2023.pdf?rlkey=7jn1nkeqddgt9ggcam1t0z4co&dl=0">PDF</Link>,{" "}
                    <Link
                      target="_blank"
                      href="https://www.dropbox.com/scl/fi/t6qzt2m10gh120ej1q6bo/International-Fiddle-Gateway-Conference-2023-Christoph-Schoenbeck.mp4?rlkey=bxxzn23nxkg0tkmpdyo8i34sn&dl=0">Video</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Typography variant="h6">
                  <Link target="_blank" href="https://www.estastrings.org/fiddle-gateway/">ESTA Folk working
                    group </Link>
                  was created at the 2024 ESTA International Conference in Porto (P)
                  <Link target="_blank" href="https://www.estastrings.org/working-groups-2/">(all working groups)</Link>
                </Typography>
              </li>
              <li>
                <Typography variant="h6">
                  2023 ESTA international conference in Cardiff presentation
                  (<Link
                  target="_blank"
                  href='https://www.dropbox.com/s/yuhvyhdgqhb6iql/2023-04-02%25252016.41.28.mov?dl=0'>
                  full video
                </Link>)
                </Typography>
              </li>
              <li>
                <Typography variant="h6">
                  <Link target="_blank" href="https://esta-2024.estaportugal.pt/fiddle-gateway-tune-learning-sessions/">
                    2024 ESTA international conference in Porto
                  </Link>
                </Typography>
              </li>
              <li>
                <Typography variant="h6">
                  <Link target="_blank" href="https://www.fotm.be/concerts/debate/">
                    Debate{" "}
                  </Link>
                  about folk education at Fiddlers on the Move 2024, Ghent, Belgium (full video is{" "}
                  <Link
                    target="_blank"
                    href="https://www.dropbox.com/scl/fi/z2l7q52gzu14in4zu6nnc/IMG_8791.MOV?rlkey=sjgzzhixuqk8r7v06x1qjbpx8&dl=0">
                    here
                  </Link
                  > )</Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Links;
