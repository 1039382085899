import React from "react";
import Layout from "../../Elements/Authentication/Layout";
import { Grid, Avatar, TextField, Typography, Link, Box } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import ServerRequests from "../../../Requests/Server";
import Authentication from "../../../Requests/UserData";
import { Container } from "@mui/system";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import logo from "../../../assets/logo/logo.png";
import Privacy from "./Privacy";
const Register = ({ setRegister }) => {
  const avatarStyle = { backgroundColor: "#1bbd7e" };
  const btnstyle = { margin: "8px 0" };
  const Navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [success, setsuccess] = new React.useState(false);
  const [backdropopen, setBackdropopen] = React.useState(false);

  const [errors, setError] = new React.useState({
    username: "",
    email: "",
    password: "",
    re_password: "",
    non_field_errors: "",
    confidentiality: "",
  });

  const [form, setForm] = new React.useState({
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    re_password: "",
  });

  const [checked, setChecked] = React.useState(false);
  const registerHandle = async (e) => {
    if (checked == false) {
      setError({ ...errors, consent: "This field is required." });
      setBackdropopen(false);
      return false;
    }
    setBackdropopen(true);
    if (form.first_name == "") {
      setError({ ...errors, first_name: "This field may not be blank." });
      setBackdropopen(false);
      return false;
    }
    if (form.last_name == "") {
      setError({ ...errors, last_name: "This field may not be blank." });
      setBackdropopen(false);
      return false;
    }
    const res = await fetch(global.config.infos.server_url + "users/", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        email: form.email,
        username: form.email,
        password: form.password,
        re_password: form.re_password,
      }),
    });

    const data = await res.json();
    if (res.ok === true) {
      setsuccess(true);
      console.log(data);
      const addnames = await ServerRequests.post_("authentication/addnames/", {
        id: data.id,
        first_name: form.first_name,
        last_name: form.last_name,
      });
      console.log(addnames);
      setBackdropopen(false);
    } else {
      setBackdropopen(false);
      setError(data);
    }
    setBackdropopen(false);
  };
  console.log(form);
  const label = { inputProps: { "aria-label": "consent" } };

  return (
    <Box
      sx={{
        my: 2,
        mx: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <img src={logo} style={{ width: "200px" }} />
      </Box>

      <Avatar
        sx={{ m: 1, bgcolor: "#add8e6", display: { xs: "none", md: "flex" } }}
      >
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign up
      </Typography>
      {success ? (
        <>
          Your account has been created successfully, check your email for
          verification. Thank you
        </>
      ) : (
        <Box sx={{ mt: 1 }}>
          <Container>
            <Grid container>
              <Grid
                item
                md={6}
                xs={6}
                sx={{
                  pr: { md: 1, xs: 0 },
                }}
              >
                <TextField
                  margin="normal"
                  fullWidth
                  sx={{ width: "100%" }}
                  id="first_name"
                  label="First name"
                  onInput={(e) =>
                    setForm({ ...form, first_name: e.target.value })
                  }
                  helperText={errors?.first_name}
                  autoFocus
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={6}
                sx={{
                  pl: { md: 1, xs: 0 },
                }}
              >
                <TextField
                  margin="normal"
                  fullWidth
                  sx={{ width: "100%" }}
                  id="last_name"
                  label="Last name"
                  onInput={(e) =>
                    setForm({ ...form, last_name: e.target.value })
                  }
                  helperText={errors?.last_name}
                  autoFocus
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  margin="normal"
                  fullWidth
                  sx={{ width: "100%" }}
                  id="email"
                  label="Email Address"
                  onInput={(e) => setForm({ ...form, email: e.target.value })}
                  helperText={errors?.email}
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Password"
                  onInput={(e) =>
                    setForm({ ...form, password: e.target.value })
                  }
                  helperText={errors?.non_field_errors || errors?.password}
                  type="password"
                  sx={{ width: "100%" }}
                  id="password"
                  autoComplete="current-password"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  margin="normal"
                  sx={{ width: "100%" }}
                  fullWidth
                  label="Confirm password"
                  onInput={(e) =>
                    setForm({ ...form, re_password: e.target.value })
                  }
                  helperText={errors?.re_password}
                  type="password"
                  id="password"
                />
              </Grid>

              <Grid item xs={12}>
                <Checkbox
                  {...label}
                  checked={checked}
                  onChange={(event) => setChecked(event.target.checked)}
                />
                <Typography variant="caption" sx={{ color: "red" }}>
                  {errors?.consent}
                </Typography>
                <small>
                  By clicking this checkbox I confirm that I have read and I
                  accept the Fiddle Gateway Database Terms of Use (naredi link!)
                  that apply to Fiddle Gateway and which include Privacy Policy,
                  and I consent to the data processing as described in the
                  Privacy Policy.
                  <Privacy />
                </small>
              </Grid>
            </Grid>
            <LoadingButton
              type="submit"
              fullWidth
              loading={loading}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={registerHandle}
            >
              Sign up
            </LoadingButton>
          </Container>
        </Box>
      )}
    </Box>
  );
};

export default Register;
