import { Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Title from "./Structure/Title/Edit";
import View from "./Structure/Title/View";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Location from "./Structure/Location/Location";
import Countries from "./Structure/Location/Countries";
import Meters from "./Structure/Features/Meters";
import Types from "./Structure/Title/Types";
import Modus from "./Structure/Features/Modus";
import BPM from "./Structure/Features/BPM";
import Level from "./Structure/Features/Level";
import Tonality from "./Structure/Features/Tonality";
import ServerRequests from "../../../Requests/Server";
import Authentication from "../../../Requests/UserData";
import moment from "moment";
import Date from "./Structure/Title/Date";
import Materials from "./Structure/Materials/Materials";
import Backdrop from "@mui/material/Backdrop";
import SendIcon from "@mui/icons-material/Send";
import Authors from "./Structure/Title/Authors"

import SaveIcon from "@mui/icons-material/Save";
import CircularProgress from "@mui/material/CircularProgress";
const steps = ["Information", "Location", "Materials", "Features"];

const Forms = ({ tune, profile }) => {
  const currentUser = Authentication.getCurrentUser();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [open, setOpen] = React.useState(false);
  const isStepOptional = (step) => {};

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const [errors, setErrors] = React.useState(null);
  const informationValidation = async () => {
    const req = await ServerRequests.post_token(
      "home/informations_validation/",
      currentUser.auth_token,
      {
        titles: titles,
        date: date,
        types: types,
      }
    );
    if (!req.ok) {
      const data = await req.json();

      setErrors(data);
      return false;
    } else {
      setErrors(null);
      return true;
    }
  };

  const handleNext = () => {
    let newSkipped = skipped;

    informationValidation().then((result) => {
      if (result == true) {
        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const [titles, setTitles] = React.useState(
    tune?.title.map((item, key) => {
      return {
        id: key,
        title: item?.title,
        editor: item?.editor,
        uid: item?.id,
      };
    })
  );
    const [date, setDate] = React.useState(null);
  const [meter, setMeter] = React.useState(null);
  const [meters, setMeters] = React.useState(
    tune?.meter.map((item, key) => {
      return {
        id: key,
        name: item?.meter_details.name,
        editor: item?.editor,
        uid: item?.id,
      };
    })
  );
  const [authors, setAuthors] = React.useState(
    tune?.author.map((item, key) => {
      const author_obj = item.author || item.author_fake
      return {
        label: `${author_obj.first_name} ${author_obj.last_name}`,
        id: author_obj.id,
        id_item: item.id,
        editor: item.editor.id,
        is_fake: item.author_fake !== null
      }
    })
  );
  const [types, setTypes] = React.useState(
    tune?.type.map((item, key) => {
      return {
        id: key,
        name: item?.type_details?.name,
        editor: item?.editor,
        uid: item?.id,
      };
    })
  );
  const [modu, setModu] = React.useState(null);
  const [bpm, setBPM] = React.useState(
    tune?.bpm.map((item, key) => {
      return {
        id: item?.id,
        name: item?.bpm_details?.name,
        editor: item?.editor,
        uid: item?.id,
      };
    })
  );
  const [level, setLevel] = React.useState(() => {
    let value = null;
    tune?.level.map((item, key) => {
      if (item?.editor == profile?.id) {
        value = item?.level;
      }
    });
    return value;
  });
  const [levelsdata, setLevelsdata] = React.useState(tune?.level);
  const [tonality, setTonality] = React.useState(
    tune?.tonality.map((item, key) => {
      return {
        id: item?.id,
        name: item?.tonality_details?.name,
        modus: item?.modus_details?.name,
        editor: item?.editor,
        uid: item?.id,
      };
    })
  );

  const [country, setCountry] = React.useState(
    tune?.country.map((item, key) => {
      return {
        name: item?.country?.name,
        code: item?.country?.code,
        editor: item?.editor,
        uid: item?.id,
      };
    })
  );
  const [region, setRegion] = React.useState(
    tune?.region.map((item, key) => {
      return {
        name: item?.region_details?.name,
        editor: item?.editor,
        uid: item?.id,
      };
    })
  );
  const [store, setStore] = React.useState([]);
  const [mataus, setMataus] = React.useState(
    tune?.material.map((item, key) => {
      return {
        id: key,
        title: item?.name,
        type: item?.type_details?.type,
        link: item?.link,
        editor: item?.editor,
        uid: item?.id,
      };
    })
  );

  const handleSubmit = () => {
    setOpen(true);
    const timer = setTimeout(() => {
      ServerRequests.post_token(
        "tune/add_edited_tune/" + tune?.id + "/",
        currentUser.auth_token,
        {
          required: {
            title: titles,
            types: types,
            date: date,
            author: authors,
          },
          location: {
            country: country,
            region: region,
          },
          features: {
            materials: mataus,
            level: level,
            tonality: tonality,
            bpm: bpm,
            modus: modu,
            meters: meters,
          },
        }
      ).then((result) => {
        handleNext();
        setOpen(false);
      });
    }, 2000);
    return () => clearTimeout(timer);

    // ;
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel
                {...labelProps}
                sx={{
                  flexFlow: "wrap",
                  justifyContent: "center",
                  fontSize: "0.75rem",
                }}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1, textAlign: "center" }}>
            Your tune got added successfully
          </Typography>
          {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box> */}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ height: 40 }} />
          {activeStep === 0 && (
            <Grid
              container
              maxWidth="false"
              spacing={{ xs: 0, md: 2 }}
              sx={{ margin: "50px auto" }}
            >
              <Grid
                item
                xs={12}
                md={8}
                sx={{
                  borderRight: "1px solid #ddd",
                  p: 2,
                }}
              >
                <Title
                  titles={titles}
                  profile={profile}
                  errors={errors}
                  setTitles={setTitles}
                />

                <br />
                <Types
                  types={types}
                  profile={profile}
                  tune={tune?.id}
                  errors={errors}
                  setTypes={setTypes}
                />
                <Authors 
                  errors={errors}
                  profile={profile}
                  tune={tune?.id}
                  setAuthors={setAuthors}
                  authors={authors}
                  titles={titles}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                sx={{ background: "#eee", width: "100%" }}
              >
                <Typography sx={{ fontWeight: 900 }}>
                  {" "}
                  Titles Preview
                </Typography>
                <Divider />
                <View
                  titles={titles}
                  tune={tune?.id}
                  profile={profile}
                  setTitles={setTitles}
                />
              </Grid>
            </Grid>
          )}

          {activeStep === 1 && (
            <Grid container sx={{ mb: 5, mt: 2 }}>
              <Grid item md={12}>
                {" "}
                <Typography sx={{ fontWeight: 900 }}>
                  {" "}
                  Region / Country
                </Typography>
              </Grid>

              <Grid item md={6} xs={12}>
                <Countries
                  country={country}
                  tune={tune?.id}
                  profile={profile}
                  setCountry={setCountry}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Location
                  region={region}
                  tune={tune?.id}
                  profile={profile}
                  setRegion={setRegion}
                />
              </Grid>
            </Grid>
          )}

          {activeStep === 2 && (
            <Grid container spacing={1} sx={{ mb: 5, mt: 2 }}>
              <Grid item md={12} sx={{ mb: 2 }}>
                <Typography sx={{ fontWeight: 900 }}> Materials</Typography>
                <Materials
                  mataus={mataus}
                  tune={tune?.id}
                  profile={profile}
                  setMataus={setMataus}
                />
              </Grid>
            </Grid>
          )}

          {activeStep === 3 && (
            <Grid container spacing={2} sx={{ mb: 5, mt: 2 }}>
              <Grid item md={12} xs={12} sx={{ mb: 2 }}>
                <Typography sx={{ fontWeight: 900 }}> Features</Typography>
              </Grid>
              <Grid item md={3} xs={12} sm={6}>
                <Typography sx={{ fontWeight: 900 }}>Level</Typography>
                <Level
                  tune={tune?.id}
                  profile={profile}
                  level={level}
                  levelsdata={levelsdata}
                  setLevel={setLevel}
                />
              </Grid>

              <Grid item md={3} xs={12} sm={6}>
                {" "}
                <Typography sx={{ fontWeight: 900 }}>BPM</Typography>
                <BPM
                  tune={tune?.id}
                  profile={profile}
                  bpm={bpm}
                  setBPM={setBPM}
                />
              </Grid>
              <Grid item md={12} xs={12} sm={6}>
                <Typography sx={{ fontWeight: 900 }}>Tonality</Typography>
                <Tonality
                  tune={tune?.id}
                  profile={profile}
                  modu={modu}
                  setModu={setModu}
                  tonality={tonality}
                  setTonality={setTonality}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography sx={{ fontWeight: 900 }}>Meter</Typography>
                <Meters
                  meter={meter}
                  setMeter={setMeter}
                  tune={tune?.id}
                  profile={profile}
                  meters={meters}
                  setMeters={setMeters}
                />
              </Grid>
            </Grid>
          )}
          <Box sx={{ height: 40 }} />
          <Box
            sx={{
              position: "absolute",
              right: 0,
              left: 0,
              bottom: 0,
              display: "flex",
              flexDirection: "row",
              pt: 1,
              pb: 1,
              background: "#747474",
              borderRadius: 5,
            }}
          >
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1, fontWeight: 900, color: "#fff" }}
              startIcon={<SendIcon sx={{ transform: "rotate(-180deg)" }} />}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}

            <Button
              onClick={
                activeStep === steps.length - 1 ? handleSubmit : handleNext
              }
              sx={{ fontWeight: 900, color: "#fff" }}
              endIcon={activeStep !== steps.length - 1 && <SendIcon />}
              startIcon={activeStep === steps.length - 1 && <SaveIcon />}
            >
              {activeStep === steps.length - 1 ? "Save" : "Next"}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

export default Forms;
