import { Button, Chip, CssBaseline, Divider, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Header from "../../Base/Header/Header";
import Sidebar from "../../Base/Sidebar/Sidebar";
import Filters from "../../Elements/Tunes/Filters";
import TunesTable from "./TunesTable";
import ServerRequests from "../../../Requests/Server";
import Authentication from "../../../Requests/UserData";
import { useDebouncedCallback } from "use-debounce";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Main = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  backgroundColor: "#eee",
}));
const Tunes = () => {
  const tableRef = React.useRef(null);
  const currentUser = Authentication.getCurrentUser();
  const [player, setPlayer] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [tunes, setTunes] = React.useState(null);
  const [fastTunes, setFastTunes] = React.useState(null);
  const [myCompetence, setMyCompetence] = React.useState(null);
  const [updated, setUpdated] = React.useState(0);
  const [filters, setFilters] = React.useState({
    combinations: 0,
    users: [],
    next: false,
  });
  const [types, setTypes] = React.useState(null);
  const [competences, setCompetence] = React.useState(null);
  const [countries, setCountries] = React.useState(null);
  const [fetched, setFetched] = React.useState(false);
  const [competupdate, setCompetupdate] = React.useState(false);
  const [disablechoose, setDisablechoose] = React.useState(false);
  const [page, setPage] = React.useState(0);
  React.useEffect(() => {
    ServerRequests.get_token("home/tunefast/", currentUser.auth_token).then(
      (response) => {
        setTunes(response);
      }
    );
    ServerRequests.get_token("home/competence/", currentUser.auth_token).then(
      (result) => {
        setCompetence(result);
      }
    );
    ServerRequests.get_token("home/types/", currentUser.auth_token).then(
      (result) => {
        setTypes(result);
      }
    );
    ServerRequests.get_token("home/countries/", currentUser.auth_token).then(
      (result) => {
        setCountries(result);
      }
    );
    setFetched(false);
    setDisablechoose(false);
  }, []);

  React.useEffect(() => {
    if (updated > 0) {
      ServerRequests.post_token(
        "home/tunefast/",
        currentUser.auth_token,
        filters
      )
        .then((response) => response.json())
        .then((response) => {
          setTunes(response);
          setFetched(false);
          setDisablechoose(false);
          setPage(0);
          if (tableRef.current) {
            tableRef.current.scrollTop = 0;
          }
        });
    }
  }, [updated]);

  const handleClose = () => {
    if (player !== null) setOpen(false);
  };
  const handleChose = () => {
    setPlayer({ naem: "hi" });
    setOpen(false);
  };

  const handleTitleSearch = useDebouncedCallback((title) => {
    setFilters({ ...filters, title: title });
    // const delayDebounceFn = setTimeout(() => {
    setUpdated((prev) => prev + 1);
    // }, 3000);
    // return () => clearTimeout(delayDebounceFn);
  }, 400);
  const handleResourceFilter = (resources) => {
    setFilters({ ...filters, resources: resources });
    setUpdated((prev) => prev + 1);
  };
  const handleSorting = (e) => {
    setFilters({ ...filters, sort: e });
    setUpdated((prev) => prev + 1);
  };
  const handleType = (value) => {
    setFilters({ ...filters, type: value });
    setUpdated((prev) => prev + 1);
  };
  const handleCountry = (value) => {
    setFilters({ ...filters, country: value });
    setUpdated((prev) => prev + 1);
  };
  const handleCompetenceChange = (value) => {
    setFilters({ ...filters, competence: value });
    setUpdated((prev) => prev + 1);
  };

  function getDifference(array1, array2) {
    if (array1 === undefined) {
      array1 = [];
    }
    if (array2 === undefined) {
      array2 = [];
    }
    return array1.filter(
      (object1) => !array2.some((object2) => object1.id === object2.id)
    );
  }

  const renderNonExistUser = (nx) => {
    const difference = [
      ...getDifference(nx, filters?.users),
      ...getDifference(filters?.users, nx),
    ];
    return (
      <>
        {difference.length === 0 && "They all can perform together"}
        {difference?.map((user, userIdx) => (
          <Chip
            key={userIdx}
            sx={{ background: "#ff4545c2" }}
            label={user.first_name + " " + user.last_name}
          />
        ))}
      </>
    );
  };
  return (
    <CssBaseline>
      <Container
        sx={{
          minHeight: "100vh",
          backgroundColor: "#eee",
        }}
        maxWidth={false}
        disableGutters
      >
        <Header />

        <Container maxWidth="xl" sx={{ flexGrow: 1, mt: 2 }}>
          <Grid container flexDirection="row" spacing={2}>
            <Grid
              item
              xs={2}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              <Sidebar />
            </Grid>
            <Grid item xs={12} md={10}>
              <Main sx={{ mb: 10 }}>
                <Filters
                  handleTitleSearch={handleTitleSearch}
                  handleResourceFilter={handleResourceFilter}
                  handleSorting={handleSorting}
                  setUpdated={setUpdated}
                  setFilters={setFilters}
                  types={types}
                  filters={filters}
                  handleType={handleType}
                  competences={competences}
                  countries={countries}
                  disablechoose={disablechoose}
                  setDisablechoose={setDisablechoose}
                  handleCompetenceChange={handleCompetenceChange}
                  handleCountry={handleCountry}
                />
                {tunes !== null ? (
                  <>
                    {tunes !== undefined && (
                      <>
                        {Array.isArray(tunes[0]) ? (
                          <>
                            {tunes?.map((items, k) => (
                              <div key={k}>
                                <Typography fontWeight={900}>
                                  {k === 0 && "All performing"}
                                  {k === 1 && "One player needs a break"}
                                </Typography>
                                {items[0]?.length === 0 && (
                                  <Typography variant="body2">
                                    There is no common tunes between those
                                    players on that level
                                  </Typography>
                                )}
                                {items[0]?.map((item, key) => (
                                  <div key={key}>
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="space-around"
                                      alignItems="stretch"
                                    >
                                      <Grid item>
                                        <Typography>Performing:</Typography>
                                        {items[1][key].map((user, userIdx) => (
                                          <Chip
                                            key={userIdx}
                                            label={
                                              user.first_name +
                                              " " +
                                              user.last_name
                                            }
                                            sx={{
                                              background: "#75ff45c2",
                                            }}
                                          />
                                        ))}
                                      </Grid>
                                      <Grid item>
                                        <Typography>Listening:</Typography>
                                        {renderNonExistUser(items[1][key])}
                                      </Grid>
                                    </Grid>

                                    <Box sx={{ mb: 2 }} />
                                    <TunesTable
                                      competences={competences}
                                      setUpdated={setUpdated}
                                      tunes={item}
                                      setTunes={setTunes}
                                      page={page}
                                      setPage={setPage}
                                      fetched={fetched}
                                      setCompetupdate={setCompetupdate}
                                      filters={filters}
                                      tableRef={tableRef}
                                    />
                                    <Box sx={{ mb: 2 }} />
                                  </div>
                                ))}
                                <Divider sx={{ m: 4 }} />
                              </div>
                            ))}
                            {(filters?.next === false) &
                            (filters?.users?.length > 1) ? (
                              <Button
                                variant="contained"
                                sx={{
                                  background: "#008982",
                                  minWidth: 200,
                                  p: 2,
                                  fontWeight: 900,
                                }}
                                onClick={() => {
                                  setFilters((prev) => ({
                                    ...prev,
                                    next: true,
                                  }));
                                  setUpdated((prev) => prev + 1);
                                }}
                              >
                                Exclude one player
                              </Button>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <TunesTable
                            competences={competences}
                            setUpdated={setUpdated}
                            tunes={tunes}
                            setTunes={setTunes}
                            page={page}
                            setPage={setPage}
                            fetched={fetched}
                            setCompetupdate={setCompetupdate}
                            filters={filters}
                            tableRef={tableRef}
                          />
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <TunesTable
                    competences={competences}
                    setUpdated={setUpdated}
                    tunes={[]}
                    setTunes={setTunes}
                    page={page}
                    setPage={setPage}
                    fetched={fetched}
                    setCompetupdate={setCompetupdate}
                    filters={filters}
                    tableRef={tableRef}
                  />
                )}
              </Main>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </CssBaseline>
  );
};

export default Tunes;
