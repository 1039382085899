import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";

import ListItemText from "@mui/material/ListItemText";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import dayjs from "dayjs";
import Authentication from "../../../Requests/UserData";
import ServerRequests from "../../../Requests/Server";
import Location from "./FilterItems/Location";
import Techniques from "./FilterItems/Techniques";
import Timing from "./FilterItems/Timing";
import Levels from "./FilterItems/Levels";
import Meters from "./FilterItems/Meters";
import Tonalities from "./FilterItems/Tonalities";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// import CountryDropdown from "country-dropdown-with-flags-for-react";

export default function Draweror({ setFilters, setUpdated, filters }) {
  const currentUser = Authentication.getCurrentUser();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const [value, setValue] = React.useState("");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);

  const handleFromDateChange = (newValue) => {
    setFrom(newValue);
    setFilters((prev) => ({
      ...prev,
      advanced: { ...prev?.advanced, created_at: newValue },
    }));
    setUpdated((prev) => prev + 1);
  };
  const handleToDateChange = (newValue) => {
    setTo(newValue);
    setFilters((prev) => ({
      ...prev,
      advanced: { ...prev?.advanced, updated_at: newValue },
    }));
    setUpdated((prev) => prev + 1);
  };
  const [bp, setBp] = React.useState(0);
  const handleChange = (event) => {
    setFilters((prev) => ({
      ...prev,
      advanced: { ...prev?.advanced, bpm: event.target.value },
    }));
    setUpdated((prev) => prev + 1);
    setBp(event.target.value);
  };

  const [tonalities, setTonanlities] = React.useState(null);
  const [meters, setMeters] = React.useState(null);
  const [meter, setMeter] = React.useState([]);

  const [techniques, setTechniques] = React.useState(null);
  const [technique, setTechnique] = React.useState([]);

  const [level, setLevel] = React.useState([]);
  const [levels, setLevels] = React.useState(null);
  const [region, setRegion] = React.useState("");
  const [bpm, setBpm] = React.useState([]);

  const [tonality, setTonality] = React.useState(0);
  const [students, setStudents] = React.useState(null);
  const [teachers, setTeachers] = React.useState(null);
  React.useEffect(() => {
    ServerRequests.get_token("home/tonalities/", currentUser.auth_token).then(
      (result) => {
        setTonanlities(result);
      }
    );

    ServerRequests.get_token("home/meters/", currentUser.auth_token).then(
      (result) => {
        setMeters(result);
      }
    );

    ServerRequests.get_token("home/techniques/", currentUser.auth_token).then(
      (result) => {
        setTechniques(result);
      }
    );

    ServerRequests.get_token("home/bpm/", currentUser.auth_token).then(
      (result) => {
        setBpm(result);
      }
    );
    ServerRequests.get_token("home/levels/", currentUser.auth_token).then(
      (result) => {
        setLevels(result);
      }
    );

    ServerRequests.get_token(
      "home/users/student/",
      currentUser.auth_token
    ).then((result) => {
      setStudents(result);
    });

    ServerRequests.get_token(
      "home/users/teacher/",
      currentUser.auth_token
    ).then((result) => {
      setTeachers(result);
    });
  }, []);

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 350 }}
      role="presentation"
      //   onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <ListItemText primary="Filters" />
          <IconButton
            color="primary"
            component="label"
            onClick={toggleDrawer("right", false)}
          >
            <CloseIcon />
          </IconButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        {/*<ListItem>*/}
        {/*  <Location*/}
        {/*    region={region}*/}
        {/*    setRegion={setRegion}*/}
        {/*    setFilters={setFilters}*/}
        {/*    setUpdated={setUpdated}*/}
        {/*  />*/}
        {/*</ListItem>*/}

        <Divider />
        {/*<ListItem>*/}
        {/*  <Timing*/}
        {/*    handleFromDateChange={handleFromDateChange}*/}
        {/*    handleToDateChange={handleToDateChange}*/}
        {/*    setUpdated={setUpdated}*/}
        {/*    from={from}*/}
        {/*    setTo={setTo}*/}
        {/*    to={to}*/}
        {/*  />*/}
        {/*</ListItem>*/}

        <Divider />

        <ListItem>
          <Levels
            setUpdated={setUpdated}
            setFilters={setFilters}
            levels={levels}
            setLevel={setLevel}
            level={level}
          />
        </ListItem>

        <ListItem>
          <Techniques
            setFilters={setFilters}
            technique={technique}
            setUpdated={setUpdated}
            setTechnique={setTechnique}
            techniques={techniques}
          />
        </ListItem>

        {/*<ListItem>*/}
        {/*  <Meters*/}
        {/*    setFilters={setFilters}*/}
        {/*    meter={meter}*/}
        {/*    setUpdated={setUpdated}*/}
        {/*    setMeter={setMeter}*/}
        {/*    meters={meters}*/}
        {/*  />*/}
        {/*</ListItem>*/}

        {/*<ListItem>*/}
        {/*  <Tonalities*/}
        {/*    tonality={tonality}*/}
        {/*    setTonality={setTonality}*/}
        {/*    setUpdated={setUpdated}*/}
        {/*    tonalities={tonalities}*/}
        {/*    setFilters={setFilters}*/}
        {/*  />*/}
        {/*</ListItem>*/}

        {/*<ListItem>*/}
        {/*  <FormControl fullWidth>*/}
        {/*    <InputLabel id="demo-simple-select-label">BPM</InputLabel>*/}
        {/*    <Select*/}
        {/*      labelId="demo-simple-select-label"*/}
        {/*      id="demo-simple-select"*/}
        {/*      label="BPM"*/}
        {/*      defaultValue={bp}*/}
        {/*      onChange={handleChange}*/}
        {/*    >*/}
        {/*      <MenuItem value={0} selected>*/}
        {/*        All*/}
        {/*      </MenuItem>*/}
        {/*      {bpm.map((item, itIdx) => (*/}
        {/*        <MenuItem value={item?.id} key={itIdx}>{item.name}</MenuItem>*/}
        {/*      ))}*/}
        {/*    </Select>*/}
        {/*  </FormControl>*/}
        {/*</ListItem>*/}
      </List>
      <Button
        sx={{ position: "absolute", right: 10, left: 10 }}
        variant="contained"
        onClick={(event) => {
          toggleDrawer("right", false)(event);
        }}
      >
        Apply filters
      </Button>
      <Button
        sx={{ position: "absolute", bottom: 10, right: 10, left: 10 }}
        variant="contained"
        onClick={(event) => {
          window.location.reload();
          // setFilters({
          //   combinations: 0,
          //   users: [],
          // });
          // setRegion(null);
          // setTo(null);
          // setFrom(null);
          // setLevel([]);
          // setTechnique([]);
          // setMeter([]);
          // setBpm([]);
          // setUpdated((prev) => prev + 1);
          // toggleDrawer("right", false)(event)
        }}
      >
        Reset All
      </Button>
    </Box>
  );

  return (
    <>
      <Button
        variant="contained"
        size="large"
        sx={{
          height: "100%",
          p: "8px 10px",
          background: "#008982",
          "&:hover": {
            backgroundColor: "#ef5b2f",
          },
        }}
        onClick={toggleDrawer("right", true)}
      >
        Advanced
      </Button>
      <Drawer
        anchor="right"
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        {list("right")}
      </Drawer>
    </>
  );
}
