import React from "react";
import { Grid } from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import loginBG from "../../../assets/bg.jpg";
import logo from "../../../assets/logo/logo.png";

import CssBaseline from "@mui/material/CssBaseline";
import { Box, Container } from "@mui/system";

const theme = createTheme();
const Layout = (props) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
        },
      }),
    [prefersDarkMode]
  );
  const paperStyle = {
    padding: 20,
    height: "70vh",
    // width: 280,
    margin: "20px auto",
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        component="main"
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <CssBaseline />
        <Grid
          item
          xs={0}
          md={7}
          sx={{
            backgroundImage: `url(${loginBG})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            display: { xs: "none", md: "block" },
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            sx={{
              backgroundColor: "rgba(0,0,0,0.8)",
            }}
          >
            <img src={logo} width="300" />
          </Box>
        </Grid>
        {props.children}
      </Grid>
    </ThemeProvider>
  );
};

export default Layout;
