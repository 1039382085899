import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Container } from "@mui/system";
import { Divider, Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ServerRequests from "../../../../../Requests/Server";
import Authentication from "../../../../../Requests/UserData";
import Autocomplete from "@mui/material/Autocomplete";
import {Box, Chip} from "@mui/material"
import Invitation from "../../../AddTune/Structure/Title/Invitation";
import Types from "./Types";
import Date from "./Date";

export default function Authors({ errors, profile, authors, setAuthors, tune, titles }) {
  const currentUser = Authentication.getCurrentUser();
  const [open, setOpen] = React.useState(false);
  const [extend, setExtend] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [counter, setCounter] = React.useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setExtend(false);
  };
  const handleSave = () => {
    ServerRequests.post_token(
      "home/learned_from/",
      currentUser.auth_token,
      authors
    ).then((result) => {
      if (result.ok) {
        setAuthors({});
        setOpen(false);
      }
    });
  };
  const handleAuthorDelete = (uid) => {
    ServerRequests.delete_token(
      `tune/delete/author/${uid}/${tune}/`,
      currentUser.auth_token
    ).then((response) => console.log(response));
  };

  React.useEffect(() => {
    if (user) {
      ServerRequests.post_token("home/users_fetch/", currentUser.auth_token, {
        word: user,
      })
        .then((result) => result.json())
        .then((response) => {
          console.log(response);
          setUsers((prev) => response);
        });
    }
  }, [user]);
  return (
    <Container
      sx={{
        border: "1px solid #ddd",
        p: 1,
        m: 1,
      }}
    >
      {extend === false ? (
        <>
          <Grid container columnSpacing={2}>
            <Grid item md={12} sx={{ mt: 2, mb: 2, ml: 1, width: "100%" }}>
              <Autocomplete
                disablePortal
                fullWidth
                sx={{
                  width: "100%",
                }}
                id="combo-box-demo"
                inputValue={user}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setAuthors([...authors, {...newValue, editor: profile?.id, id_item: -1}]);
                  }
                }}
                clearOnBlur
                onInputChange={(event, newInputValue) => {
                  console.log("INPUT ", newInputValue);
                  if (newInputValue) {
                    setUser(newInputValue);
                  } else {
                    setUser("");
                    setUsers([]);
                  }
                }}
                options={users}
                noOptionsText={
                  user?.length > 2 && (
                    <Typography>
                      If you couldn't found it in our database click to:
                      <Button
                        onClick={() => {
                          setExtend((prev) => true);
                          setUser(null);
                        }}
                      >
                        Add new
                      </Button>
                    </Typography>
                  )
                }
                renderInput={(params) => (
                  <TextField {...params} label="Add new author" />
                )}
              />
              <Box sx={{ mt: 2 }}>
                {authors?.map((item, index) => (
                  <Chip
                    label={
                      item?.label || item?.first_name + " " + item?.last_name
                    }
                    sx={{ mr: 1 }}
                    onDelete={() => {
                      setAuthors(authors.filter((item2) => item2.id !== item.id))
                      handleAuthorDelete(item.id_item)
                    }}
                    disabled={
                      (item?.editor !== undefined) &
                      (item?.editor !== profile?.id)
                    }
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="First name"
                onInput={(e) =>
                  setUser({ ...user, first_name: e.target.value })
                }
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="name"
                label="Last name"
                onInput={(e) => setUser({ ...user, last_name: e.target.value })}
                fullWidth
                variant="standard"
              />
            </Grid>
            <Typography sx={{ p: 2, mt: 2 }}>
              If you have an email address of the person who you believe
              composed this tune, you can invite her/him to the community of
              fiddle tunes, educators and players! <br />
              Thank you for spreading the idea!
              <br />
              <Invitation
                store={authors}
                setExtend={setExtend}
                titles={titles}
                setUser={setUser}
                user={user}
                setStore={setAuthors}
                setCounter={setCounter}
                counter={counter}
              />
            </Typography>
          </Grid>
        </>
      )}
    </Container>
  );
}
