import { Autocomplete, Chip, InputLabel, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ServerRequests from "../../../../../Requests/Server";
import Authentication from "../../../../../Requests/UserData";
const Countries = ({ country, setCountry, profile, tune }) => {
  const currentUser = Authentication.getCurrentUser();
  const [countries, setCountries] = React.useState(null);
  React.useEffect(() => {
    ServerRequests.get_token("home/countries/", currentUser.auth_token).then(
      (result) => setCountries(result)
    );
  }, []);
  const handleCountryDelete = (uid) => {
    ServerRequests.delete_token(
      "tune/delete/country/" + uid + "/" + tune + "/",
      currentUser.auth_token
    ).then((response) => console.log(response));
  };
  return (
    <Box
      sx={{
        width: "100%",
        display: "block",
        margin: "auto",
        mt: 2,
      }}
    >
      {countries !== null && (
        <Autocomplete
          multiple
          disableClearable={true}
          id="tags-outlined"
          onChange={(event, newValue, reason, detail) => {
            if (reason === "removeOption") {
              if (detail.option.uid != undefined) {
                handleCountryDelete(detail.option.uid);
              }
            }
            setCountry(newValue);
          }}
          options={countries || []}
          defaultValue={country}
          getOptionLabel={(option) => option?.name}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={option.name}
                {...getTagProps({ index })}
                disabled={
                  (option?.editor !== undefined) &
                  (option?.editor !== profile?.id)
                }
              />
            ))
          }
          filterSelectedOptions
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.name} ({option.code})
            </Box>
          )}
          renderInput={(params) => (
            <TextField {...params} placeholder="Countries.." />
          )}
        />
      )}
    </Box>
  );
};

export default Countries;
