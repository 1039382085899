import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

import { Container } from "@mui/system";
import { Divider, Grid, Link, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import ServerRequests from "../../../../../Requests/Server";
import Authentication from "../../../../../Requests/UserData";

export default function Invitation({
  setStore,
  store,
  titles,
  setExtend,
  setUser,
  user,
  setCounter,
  counter,
}) {
  const currentUser = Authentication.getCurrentUser();
  const [open, setOpen] = React.useState(false);
  const [profile, setProfile] = React.useState(null);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const handleSending = () => {
    if (user?.email == "" || user?.email === undefined) {
      setError("An email is required to accomplish this step");
      return false;
    }
    ServerRequests.post_token("home/invitation/", currentUser.auth_token, {
      email: user?.email,
      titles: titles.length > 0 ? titles[0].title : "",
    }).then((response) => {
      if (response.ok === true) {
        setSuccess(true);
        setOpen(false);
        setCounter((prev) => prev - 1);
        setStore([...store, { ...user, id: counter }]);
        setExtend(false);
        setUser("");
      } else {
        setError("An error occured, try again later..");
      }

    });

    //
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    ServerRequests.get_token(
      "authentication/profile/",
      currentUser.auth_token
    ).then((response) => setProfile(response));
  }, [store]);
  return (
    <div>
      <Button
        variant="filled"
        sx={{
          background: "#ef5b2f78",
          color: "#008982",
          fontWeight: 800,
          mt: 2,
          mr: 2,
        }}
        onClick={() => {
          if (user?.first_name != undefined || user?.last_name != undefined) {
            setCounter((prev) => prev - 1);
            setStore((prev) => [...prev, { ...user, id: counter }]);
          }

          setExtend(false);
          setUser("");
        }}
      >
        Save/back
      </Button>
      {success === false ? (
        <Button
          variant="filled"
          sx={{
            background: "#ef5b2f78",
            color: "#008982",
            fontWeight: 800,
            mt: 2,
          }}
          onClick={handleClickOpen}
        >
          Send an invitation
        </Button>
      ) : (
        <Typography sx={{ color: "green" }}>
          Your inviation has been sent. Thank you
        </Typography>
      )}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Send an invitation?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container columnSpacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Email"
                  type="email"
                  helperText={error}
                  onInput={(e) => setUser({ ...user, email: e.target.value })}
                  fullWidth
                  variant="standard"
                />
              </Grid>
            </Grid>
            <Typography>
              <br />
              <strong>message</strong>: <br />
              You received this e-mail, because{" "}
              {profile?.first_name + " " + profile?.last_name} thinks you might
              want to connect with Fiddle Gateway. <br />
              Your e-mail address was provided to us only in order to invite you
              to join our community and will not be stored by us or revealed to
              anyone. Please see{" "}
              {profile?.first_name + " " + profile?.last_name}'s invitation
              below:
              <br />
              Hi,
              <br />I was editing tunes on the website{" "}
              <Link href="www.fiddle-gateway.eu">www.fiddle-gateway.eu</Link>. I
              think you are the author of the tune, called "
              {titles?.length > 0 && titles[0]?.title}".
              <br />
              Welcome to register at{" "}
              <Link href="www.fiddle-gateway.eu">
                www.fiddle-gateway.eu
              </Link>{" "}
              and join the community of fiddle tunes, educators and players!{" "}
              <br />
              Best regards,
              <br />
              {profile?.first_name + " " + profile?.last_name}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus variant="contained" onClick={handleClose}>
            Disagree
          </Button>
          <Button variant="contained" onClick={handleSending} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
