import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const Meters = ({ meters, meter, setMeter, setUpdated, setFilters }) => {
  console.log("Metter ______________", meter);
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Meter</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="Meters"
        defaultValue={meter}
        onChange={(e) => {
          setFilters((prev) => ({
            ...prev,
            advanced: { ...prev?.advanced, meter: e.target.value },
          }));
          setUpdated((prev) => prev + 1);
          setMeter(e.target.value);
        }}
      >
        <MenuItem value={0} selected>
          All
        </MenuItem>
        {meters?.map((item) => (
          <MenuItem value={item.id}>{item.meter}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Meters;
