import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const Tonalities = ({
  tonalities,
  setFilters,
  setUpdated,
  tonality,
  setTonality,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Tonality</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="Tonality"
        defaultValue={tonality}
        onChange={(e) => {
          setFilters((prev) => ({
            ...prev,
            advanced: { ...prev?.advanced, tonality: e.target.value },
          }));
          setTonality(e.target.value);
          setUpdated((prev) => prev + 1);
        }}
      >
        <MenuItem value={0} selected>
          All
        </MenuItem>
        {tonalities?.map((item) => (
          <MenuItem value={item?.id}> {item?.tonality}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Tonalities;
