import * as React from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
const Timing = ({ from, to, handleFromDateChange, handleToDateChange }) => {
  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        localeText={{ start: "Check-in", end: "Check-out" }}
      >
        <DesktopDatePicker
          label="Created After"
          inputFormat="MM/DD/YYYY"
          value={from}
          onChange={handleFromDateChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>

      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        localeText={{ start: "Check-in", end: "Check-out" }}
      >
        <DesktopDatePicker
          label="Edited After"
          inputFormat="MM/DD/YYYY"
          value={to}
          onChange={handleToDateChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </>
  );
};

export default Timing;
