import React from "react";
import Layout from "../../Elements/Authentication/Layout";
import {
  Grid,
  Avatar,
  TextField,
  Typography,
  Link,
  Box,
  Button,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useParams, useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import ServerRequests from "../../../Requests/Server";
import Authentication from "../../../Requests/UserData";
import Register from "./Register";
import Verification from "./Verification";
import par1 from "../../../assets/partners/par1.png";
import par2 from "../../../assets/partners/par2.png";
import par3 from "../../../assets/partners/par3.png";
import par4 from "../../../assets/partners/par4.png";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import logo from "../../../assets/logo/logo.png";
import Privacy from "./Privacy";

const itemData = [
  {
    img: par2,
    title: "Burger",
    rows: 1,
    cols: 3,
  },
  {
    img: par3,
    title: "Camera",
    rows: 1,
    cols: 3,
  },
  {
    img: par4,
    title: "Camera",
    rows: 1,
    cols: 6,
  },
  {
    img: par1,
    title: "Breakfast",
    rows: 1,
    cols: 12,
  },
];
function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}
function Copyright(props) {
  return (
    <Box>
      <Typography
        variant="body2"
        component="div"
        color="text.secondary"
        align="center"
        {...props}
        sx={{ borderTop: "1px solid #eee", mt: 5, pt: 3 }}
      >
        <Privacy />
        {"Copyright © "}
        <Link color="inherit" href="https://fiddle-gateway.com/">
          Fiddle Gateway
        </Link>{" "}
        {new Date().getFullYear()}
      </Typography>
      <ImageList
        sx={{ width: "100%", px: 4 }}
        variant="quilted"
        cols={12}
        rowHeight={121}
      >
        {itemData.map((item) => (
          <ImageListItem
            key={item.img}
            cols={item.cols || 1}
            rows={item.rows || 1}
          >
            <img
              {...srcset(item.img, 121, item.rows, item.cols)}
              alt={item.title}
              loading="lazy"
              style={{ objectFit: "contain" }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}
const Login = () => {
  let { uid, token } = useParams();
  const avatarStyle = { backgroundColor: "#1bbd7e" };
  const btnstyle = { margin: "8px 0" };
  const Navigate = useNavigate();
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [register, setRegister] = React.useState(false);
  const [forgot, setForgot] = React.useState({
    status: 0,
    step: 0,
  });
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = await new FormData(event.currentTarget);

    console.log(global.config.infos.server_url);
    try {
      const auth = await ServerRequests.post_("token/login/", {
        email: data.get("email"),
        password: data.get("password"),
      });
      const bata = await auth.json();
      if (auth.ok) {
        localStorage.setItem("user", JSON.stringify(bata));
        setLoading(false);
        return Navigate("/");
      } else {
        setErrors(bata);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const [passwords, setPasswords] = React.useState({
    new_password: "",
    re_new_password: "",
  });
  const [forgotErrors, setForgotErrors] = React.useState({
    email: "",
    code: "",
    new_password: "",
    re_new_password: "",
  });
  const [email, setEmail] = React.useState("");

  const handleForgetPassword = async () => {
    setLoading(true);
    if (email === "") {
      setForgotErrors({ ...forgotErrors, email: "This field is required" });
      return false;
    }
    try {
      const reset = await ServerRequests.post_(
        "authentication/passwordforget/",
        {
          email: email,
        }
      );
      if (reset.ok === true) {
        setForgot({
          status: 1,
          step: 1,
        });
        setForgotErrors({});
        setLoading(false);
      } else {
        const response = await reset.json();
        setForgotErrors(response);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const [code, setCode] = React.useState("");
  const [tempoData, setTempoData] = React.useState(null);
  const handleCodeCheck = async () => {
    try {
      const reset = await ServerRequests.post_("authentication/codecheck/", {
        email: email,
        code: code,
      });
      if (reset.ok === true) {
        const tempo = await reset.json();
        setForgot({
          status: 1,
          step: 2,
        });
        setTempoData(tempo);
        setForgotErrors({});
      } else {
        setForgotErrors({
          ...forgotErrors,
          code: "The code doesn't exist, try again...",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  console.log(passwords);
  const NewPasswordSet = async () => {
    try {
      const reset = await ServerRequests.post_(
        "authentication/reset_password_confirm/",
        {
          new_password: passwords.new_password,
          re_new_password: passwords.re_new_password,
          email: email,
          code: code
        }
      );
      if (reset.ok === true) {
        setForgot({
          status: 1,
          step: 3,
        });
        setForgotErrors({});
      } else {
        const response = await reset.json();
        console.log(response);
        setForgotErrors(response);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const login_render = () => {
    return (
      <Box
        sx={{
          mx: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <img src={logo} style={{ width: "200px" }} alt="rando logo" />
        </Box>

        <Avatar
          sx={{
            m: 1,
            bgcolor: "#add8e6",
            display: { xs: "none", md: "flex" },
          }}
        >
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Log In
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            helperText={errors?.email}
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            helperText={errors?.non_field_errors || errors?.password}
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <LoadingButton
            type="submit"
            fullWidth
            loading={loading}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Log In
          </LoadingButton>
        </Box>
      </Box>
    );
  };

  const forgetPassword_render = () => {
    return (
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <img src={logo} style={{ width: "200px" }} />
        </Box>

        <Avatar
          sx={{ m: 1, bgcolor: "#add8e6", display: { xs: "none", md: "flex" } }}
        >
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot password
        </Typography>
        <Box sx={{ mt: 1 }}>
          {forgot.step === 0 && (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                onInput={(e) => setEmail(e.target.value)}
                helperText={forgotErrors?.email}
                name="email"
                autoComplete="email"
                autoFocus
              />

              <LoadingButton
                onClick={handleForgetPassword}
                fullWidth
                loading={loading}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Submit
              </LoadingButton>
            </>
          )}

          {forgot.step === 1 && (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="code"
                onInput={(e) => setCode(e.target.value)}
                label="Code"
                helperText={forgotErrors?.code}
                name="code"
                autoFocus
              />

              <LoadingButton
                onClick={handleCodeCheck}
                fullWidth
                loading={loading}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Submit
              </LoadingButton>
            </>
          )}

          {forgot.step === 2 && (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                type="password"
                id="code"
                onInput={(e) =>
                  setPasswords({ ...passwords, new_password: e.target.value })
                }
                label="Password"
                helperText={errors?.password}
                name="code"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                type="password"
                onInput={(e) =>
                  setPasswords({
                    ...passwords,
                    re_new_password: e.target.value,
                  })
                }
                id="code"
                label="Password Confirmation"
                helperText={errors?.re_new_password}
                name="code"
              />

              <LoadingButton
                onClick={NewPasswordSet}
                fullWidth
                loading={loading}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Submit
              </LoadingButton>
            </>
          )}
          {forgot.step === 3 && (
            <>
              Your password has been changed you can now login freely using your
              new password
              <br />
            </>
          )}

          <Grid container>
            <Grid item xs>
              <Link
                href="#"
                onClick={() =>
                  setForgot({
                    status: 0,
                    step: 0,
                  })
                }
                variant="body2"
              >
                Login?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };
  return (
    <Layout>
      <Grid
        item
        xs={12}
        md={5}
        square="true"
        sx={{ overflow: "auto", height: "100%" }}
      >
        {token ? (
          <Verification uid={uid} token={token} />
        ) : (
          <>
            {forgot.status === 0 && (
              <>
                {register ? (
                  <Register setRegister={setRegister} />
                ) : (
                  login_render()
                )}
              </>
            )}

            {forgot.status === 1 && forgetPassword_render()}
            <Box
              sx={{
                my: 2,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid container maxWidth="lg">
                <Grid item xs>
                  <Button
                    href="#"
                    onClick={() =>
                      setForgot({
                        status: 1,
                        step: 0,
                      })
                    }
                    variant="body2"
                  >
                    Forgot password?
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    s="true"
                    onClick={() => setRegister((prev) => !prev)}
                    variant="body2"
                  >
                    {register ? (
                      <> {"I have an account"}</>
                    ) : (
                      <> {"Don't have an account?"}</>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
        <Copyright sx={{ mt: 5 }} />
      </Grid>
    </Layout>
  );
};

export default Login;
