import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const SelectCell = ({ data, rowid, def, handleChange }) => {
  const [selected, setSelected] = React.useState(def || 1);
  return (
    <FormControl fullWidth>
      <Select
        value={selected}
        // displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        onChange={(e) => {
          handleChange(e, rowid);
          setSelected(e.target.value);
        }}
      >
        <MenuItem value="" disabled>
          Select...
        </MenuItem>
        {data?.map((item, itemIdxs) => (
          <MenuItem value={item.id} key={itemIdxs}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectCell;
