const get_ = async (url) => {
  const res = await fetch(global.config.infos.server_url + url, {
    headers: {
      "Content-type": "application/json",
    },
  });
  const result = await res.json();
  return result;
};
const get_token = async (url, token) => {
  const res = await fetch(global.config.infos.server_url + url, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Token " + token,
    },
  });
  const result = await res.json();
  if (res.status === 401) {
    localStorage.clear();
    return false;
  }

  return result;
};

const get_token_data = async (url, token, data = {}) => {
  const res = await fetch(global.config.infos.server_url + url, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify(data),
  });
  const result = await res.json();
  if (res.status === 401) {
    localStorage.clear();
    return false;
  }

  return result;
};

const post_ = async (url, data = "") => {
  const res = await fetch(global.config.infos.server_url + url, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return res;
};

const post_token = async (url, token, data = {}) => {
  const res = await fetch(global.config.infos.server_url + url, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify(data),
  });
  return res;
};

const post_token_f = async (url, token, data = {}) => {
  const res = await fetch(global.config.infos.server_url + url, {
    method: "POST",
    headers: {
      "Content-type":
        "multipart/form-data; boundary=----WebKitFormBoundary06f6g54NVbSieT6y",
      Authorization: "Token " + token,
    },
    body: JSON.stringify(data),
  });
  return res;
};
const put_ = async (url, data = {}) => {
  const res = await fetch(global.config.infos.server_url + url, {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  });

  return res;
};

const put_token = async (url, token, data = {}) => {
  const res = await fetch(global.config.infos.server_url + url, {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify(data),
  });
  return res;
};

const patch_token = async (url, token, data = {}) => {
  const res = await fetch(global.config.infos.server_url + url, {
    method: "PATCH",
    headers: {
      "Content-type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify(data),
  });
  return res;
};

const delete_token = async (url, token) => {
  const res = await fetch(global.config.infos.server_url + url, {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      Authorization: "Token " + token,
    },
  });
  return res;
};

const ServerRequests = {
  get_,
  get_token,
  get_token_data,
  post_,
  post_token,
  post_token_f,
  put_,
  put_token,
  patch_token,
  delete_token,
};

export default ServerRequests;
