import { Avatar, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import moment from "moment";

const Informations = ({ profile }) => {
  return (
    <Box sx={{ textAlign: "center" }}>
      <Avatar
        src=""
        alt={profile?.first_name + " " + profile?.last_name}
        sx={{
          margin: "auto",
        }}
      />
      <Typography sx={{ m: 1, fontWeight: 800 }}>
        {profile?.first_name + " " + profile?.last_name}
      </Typography>

      {/* <Typography sx={{ m: 1 }}>
        {moment(profile?.created_at).format("LL")}
      </Typography> */}
    </Box>
  );
};

export default Informations;
