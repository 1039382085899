import * as React from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import { Typography } from "@mui/material";
const Techniques = ({
  techniques,
  technique,
  setTechnique,
  setUpdated,
  setFilters,
}) => {
  console.log(techniques);
  return (
    <FormControl fullWidth>
      <Typography id="demo-simple-select-label">Techniques</Typography>
      <Autocomplete
        multiple
        id="tags-outlined"
        defaultValue={technique}
        limitTags={1}
        onChange={(event, newValue) => {
          setFilters((prev) => ({
            ...prev,
            advanced: { ...prev?.advanced, technique: newValue },
          }));
          setTechnique(newValue);
          setUpdated((prev) => prev + 1);
        }}
        options={techniques || []}
        getOptionLabel={(option) => option?.name}
        sx={{
          width: "100%",
        }}
        filterSelectedOptions
        renderInput={(params) => <TextField {...params} />}
      />
    </FormControl>
  );
};

export default Techniques;
