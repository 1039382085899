import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
// import FavoriteIcon from "@mui/icons-material/Favorite";
// import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Paper from "@mui/material/Paper";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import Drawer from "@mui/material/Drawer";
// import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
// import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
// import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ListItemText from "@mui/material/ListItemText";
import Logout from "@mui/icons-material/Logout";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import {useNavigate} from "react-router-dom";
// import ContactSupportIcon from "@mui/icons-material/ContactSupport";
// import ContactMailIcon from "@mui/icons-material/ContactMail";
import Avatar from "@mui/material/Avatar";

import MenuList from "@mui/material/MenuList";
// import ContentCut from "@mui/icons-material/ContentCut";
// import ContentCopy from "@mui/icons-material/ContentCopy";
// import ContentPaste from "@mui/icons-material/ContentPaste";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import GrainIcon from "@mui/icons-material/Grain";
// import Cloud from "@mui/icons-material/Cloud";
import {Link} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";

export default function BottomMenu({handleLogout}) {
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    left: false,
  });
  const [anchorElMessage, setAnchorElMesaage] = React.useState(null);

  const openMessage = Boolean(anchorElMessage);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({...state, [anchor]: open});
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMessageClick = (event) => {
    setAnchorElMesaage(event.currentTarget);
  };

  const handleMessageClose = () => {
    setAnchorElMesaage(null);
  };
  const list = (anchor) => (
    <Box
      sx={{width: anchor === "top" || anchor === "bottom" ? "auto" : 250}}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <MenuList>
        {/* <MenuItem sx={{ whiteSpace: "break-spaces" }} component={Link} href="/">
          <ListItemIcon>
            <HomeIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={{ textAlign: "left" }}>Dashboard</ListItemText>
        </MenuItem>
        <Divider /> */}
        <MenuItem
          sx={{whiteSpace: "break-spaces"}}
          component={Link}
          href="/common_tunes"
        >
          <ListItemIcon>
            <GrainIcon fontSize="small"/>
          </ListItemIcon>
          <ListItemText sx={{textAlign: "left"}}>Common tunes</ListItemText>
        </MenuItem>
        {/* <Divider />
        <MenuItem
          sx={{ whiteSpace: "break-spaces" }}
          component={Link}
          href="/my_tunes"
        >
          <ListItemIcon>
            <Cloud fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={{ textAlign: "left" }}>My Tunes</ListItemText>
        </MenuItem>{" "} */}
        <Divider/>
        <MenuItem
          sx={{whiteSpace: "break-spaces"}}
          component={Link}
          href="/add_tune"
        >
          <ListItemIcon>
            <LibraryAddIcon fontSize="small"/>
          </ListItemIcon>
          <ListItemText sx={{textAlign: "left"}}>Add Tune</ListItemText>
        </MenuItem>
        <Divider/>
        <MenuItem
          sx={{whiteSpace: "break-spaces"}}
          component={Link}
          href="/links"
        >
          <ListItemIcon>
            <LinkIcon fontSize="small"/>
          </ListItemIcon>
          <ListItemText sx={{textAlign: "left"}}>Links</ListItemText>
        </MenuItem>
      </MenuList>
    </Box>
  );
  return (
    <Box sx={{display: {md: "none", xs: "block"}}}>
      <CssBaseline/>
      <Paper
        sx={{position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 999}}
        elevation={3}
      >
        <BottomNavigation showLabels>
          <BottomNavigationAction
            label="Menu"
            onClick={toggleDrawer("left", true)}
            icon={<MenuOpenIcon/>}
          />
          <Drawer
            anchor={"left"}
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
          >
            {list("left")}
          </Drawer>
          {/* <BottomNavigationAction
            label="Dashboard"
            sx={{ p: { xs: "6px 12px 12px" } }}
          /> */}

          <BottomNavigationAction
            label="Account"
            icon={<PersonOutlineIcon/>}
            onClick={handleClick}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          />
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                p: 2,
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  bottom: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(90deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{horizontal: "right", vertical: "top"}}
            anchorOrigin={{horizontal: "right", vertical: "bottom"}}
          >
            <MenuItem component={Link} href="/profile">
              <Avatar/> Profile
            </MenuItem>
            {/* <MenuItem>
              <Avatar /> My account
            </MenuItem> */}
            <Divider/>

            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small"/>
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
