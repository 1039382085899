import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Snackbar from "@mui/material/Snackbar";
import ServerRequests from "../../../Requests/Server";
import Authentication from "../../../Requests/UserData";
import SelectCell from "./SelectCell";
import {
  Button,
  ButtonGroup,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import OneTune from "./OneTune";
import Skeleton from "@mui/material/Skeleton";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
const colors = {
  3: "#ef5b2f",
  4: "#008982",
  2: "#747474",
};

const columns = [
  { id: "title", label: "Title", minWidth: 170 },
  // {
  //   id: "tune_type",
  //   label: "Tune Type",
  //   minWidth: 170,
  //   align: "right",
  //   format: (value) => value.toLocaleString("en-US"),
  // },

  // { id: "type", label: "Type", minWidth: 100 },
  // { id: "country", label: "Country", minWidth: 100 },
  {
    id: "competence",
    label: "My Competence",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "players",
    label: "Players ",
    minWidth: 70,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "actions",
    label: "Actions ",
    minWidth: 80,
    align: "right",
  },
];

// const competences = [
//   {
//     id: "wishlist",
//     title: "Wish list",
//   },
//   {
//     id: "elementary",
//     title: "Elementary",
//   },
//   {
//     id: "professional",
//     title: "Professional",
//   },
// ];

const text = (
  <>
    <Typography sx={{ fontWeight: 800 }}>Track your repertoire.</Typography>{" "}
    <Typography>
      Mark your personal competence in the drop-down menu next to the tune.
    </Typography>
    <ul>
      <li>NONE - I can’t play the tune.</li>
      <li>
        WISH LIST - I would like to learn this tune. Filtering tunes by
        competence and your name, you can see your ‘to learn’ list.
      </li>
      <li>
        BASIC - I can play this tune if somebody else is leading. I am not ready
        for solo performing. I can’t play accompaniment.
      </li>
      <li>
        ADVANCED - I can start a tune, I can play it as a solo performance, I
        can also play accompaniment.
      </li>
    </ul>
  </>
);

export default function TunesTable({
  tunes,
  setTunes,
  competences,
  setUpdated,
  fetched,
  page,
  setPage,
  setCompetupdate,
  filters,
  tableRef
}) {
  const currentUser = Authentication.getCurrentUser();

  const rowsPerPageStored = localStorage.getItem('rowsPerPage')
  const rowsPerPageParsed = rowsPerPageStored ? parseInt(rowsPerPageStored) : 25
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageParsed);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    localStorage.setItem('rowsPerPage', event.target.value)
  };
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  
  const handleCompetenceChange = (event, index) => {
    ServerRequests.post_token("home/competence/", currentUser.auth_token, {
      tune: index,
      competence: event.target.value,
      filters: filters
    })
    .then((result) => result.json())
    .then((result) => {
      setMessage("Competence set successfully");
      setOpen(true);
      setTunes(result)
      // setUpdated((prev) => prev + 1);
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  // console.log(tunes);
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
        message={message}
        key="topright"
      />
      <TableContainer ref={tableRef} sx={{ maxHeight: 540 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                  {column.id === "competence" && (
                    <Tooltip disableFocusListener title={text}>
                      <InfoIcon
                        sx={{ fontSize: "20px", position: "absolute", ml: 1 }}
                      />
                    </Tooltip>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fetched === true ? (
              <TableRow>
                {columns?.map((column, idx) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Skeleton animation="wave" />
                  </TableCell>
                ))}
              </TableRow>
            ) : (
              <>
                {tunes
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, key) => {
                    return (
                      <TableRow
                        key={row.id}
                        sx={{
                          background: colors?.[row["competence"]],
                        }}
                        role="checkbox"
                        tabIndex={-1}
                      >
                        {columns?.map((column, colIdx) => {
                          return (
                            <TableCell key={colIdx} align={column.align}>
                              {column.id === "competence" && (
                                <SelectCell
                                  data={competences}
                                  def={row["competence"]}
                                  rowid={row["id"]}
                                  handleChange={handleCompetenceChange}
                                />
                              )}
                              {column.id === "title" &&
                                row[column?.id].map((item, itIdx) => (
                                  <Typography key={itIdx}>- {item.title}</Typography>
                                ))}

                              {column.id === "type" &&
                                row[column?.id].map((item, key2) => (
                                  <Typography key={key2}>
                                    - {item?.type_details?.name}
                                  </Typography>
                                ))}
                              {column.id === "country" &&
                                row[column?.id].map((item, key3) => (
                                  <Typography key={key3}>- {item.country.name}</Typography>
                                ))}

                              {column.id === "players" && (
                                <Typography sx={{ fontWeight: 900 }}>
                                  {row[column?.id]}
                                </Typography>
                              )}

                              {column.id === "actions" && (
                                <ButtonGroup
                                  disableElevation
                                  variant="contained"
                                  aria-label="Disabled elevation buttons"
                                > 
                                  {/* TODO FIX backend data to include this */}
                                  <OneTune tune={row} />

                                  <IconButton
                                    component={Link}
                                    href={"/edit_tune/" + row["id"]}
                                    sx={{ color: "#000" }}
                                    aria-label="add to shopping cart"
                                    size="small"
                                  >
                                    <EditIcon fontSize="inherit" />
                                  </IconButton>
                                </ButtonGroup>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={tunes?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
