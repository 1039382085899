import React from "react";
import { Autocomplete, Chip, InputLabel, TextField } from "@mui/material";
import { Box } from "@mui/system";

import ServerRequests from "../../../../../Requests/Server";
import Authentication from "../../../../../Requests/UserData";

const BPM = ({ bpm, setBPM, profile, tune }) => {
  const currentUser = Authentication.getCurrentUser();
  const [bpms, setBmps] = React.useState(null);
  React.useEffect(() => {
    ServerRequests.get_token("home/bpm/", currentUser.auth_token).then(
      (result) => setBmps(result)
    );
  }, []);

  const handleBPMDelete = (uid) => {
    ServerRequests.delete_token(
      "tune/delete/bpm/" + uid + "/" + tune + "/",
      currentUser.auth_token
    ).then((response) => console.log(response));
  };
  return (
    <Box>
      <Autocomplete
        multiple
        id="tags-filled"
        limitTags={2}
        options={bpms || []}
        defaultValue={bpm}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }

          return option.name;
        }}
        onChange={(event, newValue, reason, detail) => {
          if (reason === "removeOption") {
            if (detail.option.uid != undefined) {
              handleBPMDelete(detail.option.uid);
            }
          }
          setBPM(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            label="BPM"
            placeholder="Options..."
          />
        )}
        disableClearable={true}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option?.name}
              {...getTagProps({ index })}
              disabled={
                (option?.editor !== undefined) &
                (option?.editor !== profile?.id)
              }
            />
          ))
        }
      />
    </Box>
  );
};

export default BPM;
