import {Container, Grid, Typography} from "@mui/material";
import {Box} from "@mui/system";
import React from "react";
// import Charts from "./Charts";
import Numbers from "./Numbers";
import par1 from "../../../assets/partners/par1.png";
import par2 from "../../../assets/partners/par2.png";
import par3 from "../../../assets/partners/par3.png";
import par4 from "../../../assets/partners/par4.png";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

const itemData = [
    {
        img: par2,
        title: "Burger",
        rows: 1,
        cols: 2,
    },
    {
        img: par3,
        title: "Camera",
        rows: 1,
        cols: 2,
    },
    {
        img: par4,
        title: "Camera",
        rows: 1,
        cols: 3,
    },
    {
        img: par1,
        title: "Breakfast",
        rows: 1,
        cols: 5,
    },
];

function srcset(image, size, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${
            size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
}

const Body = () => {
    return (
        <Box>
            <Numbers/>
            {/* <Charts /> */}
            <Box/>
            <Container>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                >
                    <Grid item xs={12} md={12} sx={{mt: 4}}>
                        <Typography variant="h5">Welcome to Fiddle Gateway!</Typography>
                        <Typography variant="h6">
                            A web database www.fiddle-gateway.eu is a tool for a better orientation within a
                            large <br/> amount of violin
                            tunes from European regions.
                        </Typography>
                        <Timeline position="alternate">
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot variant="contained" sx={{color: "#ef5b2f"}}/>
                                    <TimelineConnector/>
                                </TimelineSeparator>
                                <TimelineContent>
                                    <a style={{textDecoration: "none"}} href="/common_tunes">
                                        <Typography
                                            variant="h6"
                                            fontWeight={800}
                                            sx={{color: "#008982"}}
                                        >
                                            Track your personal repertoire
                                        </Typography>
                                    </a>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot variant="contained" sx={{color: "#008982"}}/>
                                    <TimelineConnector/>
                                </TimelineSeparator>
                                <TimelineContent>
                                    <a style={{textDecoration: "none"}} href="/common_tunes">
                                        <Typography
                                            variant="h6"
                                            fontWeight={800}
                                            sx={{color: "#ef5b2f"}}
                                        >
                                            Find common tunes with your friends
                                        </Typography>
                                    </a>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot variant="contained" sx={{color: "#ef5b2f"}}/>
                                    <TimelineConnector/>
                                </TimelineSeparator>
                                <TimelineContent>
                                    <a style={{textDecoration: "none"}} href="/common_tunes">
                                        <Typography
                                            variant="h6"
                                            fontWeight={800}
                                            sx={{color: "#008982"}}
                                        >
                                            Make your wish list of tunes to learn
                                        </Typography>
                                    </a>
                                </TimelineContent>
                            </TimelineItem>
                        </Timeline>
                        <Typography variant="h6" textAlign="left">
                            You can contribute the content to the database or edit existing tunes. But you can not
                            delete other people’s different opinions (country, title,…). So it is also a collection of
                            all (possibly) wrong opinions which makes searching easier.
                        </Typography>
                        <br/>
                        <Typography variant="h6" textAlign="left">
                            The database was created as a part of Erasmus+ project European Fiddle Gate. The project
                            connected European influent folk violin educators at two one-week conferences (2022 and
                            2023) at Bahnhof Leisnig in Germany. The discussion was about connecting traditional music
                            within the established classical and jazz curriculum.
                        </Typography>
                        <br/>
                        <Typography variant="h6" textAlign="left">
                            Our long-term goals are:
                            <ul>
                                <li>definition of the classic repertoire in specific regions (what should I learn if I
                                    travel to join the local jam session abroad?)
                                </li>
                                <li>which are the most played tunes? (tunes to learn if you don’t want to dig deeper
                                    into folk music studies)
                                </li>
                                <li>assisting non-folk teachers at choosing tunes for their students (level, region,
                                    technical speciality)
                                </li>
                                <li>creating set lists (connecting to folk venues)</li>
                                <li>tracking informal education influence index (who did I learn the tune from?)</li>
                            </ul>
                        </Typography>
                        <Typography variant="h6">
                            {" "}
                            {/* We will let you know about the release date soon! <br /> */}
                            Keep on jamming!
                        </Typography>
                        <Typography variant="h6" sx={{mb: 4}}>
                            Your <br/>
                            Fiddle Gateway team
                        </Typography>
                        <Typography variant="h6" textAlign="center">
                            Disclaimer:
                        </Typography>
                        <Typography variant="h6" textAlign="left">
                            Funded by the European Union. Views and opinions expressed are however those of the
                            author(s) only and do not necessarily reflect those of the European Union or the European
                            Education and Culture Executive Agency (EACEA). Neither the European Union nor EACEA can be
                            held responsible for them.
                        </Typography>
                    </Grid>
                    <Grid item md={4}>
                        <Typography fontWeight={900} textAlign="left" variant="h3">
                            Partners
                        </Typography>
                    </Grid>

                    <Grid item md={8}>
                        <ImageList
                            sx={{width: "100%", px: 4}}
                            variant="quilted"
                            cols={12}
                            rowHeight={100}
                        >
                            {itemData.map((item) => (
                                <ImageListItem
                                    key={item.img}
                                    cols={item.cols || 1}
                                    rows={item.rows || 1}
                                >
                                    <img
                                        {...srcset(item.img, 121, item.rows, item.cols)}
                                        alt={item.title}
                                        loading="lazy"
                                        style={{objectFit: "contain"}}
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Body;
