import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import ServerRequests from "../../../../../Requests/Server";
import Authentication from "../../../../../Requests/UserData";
import { Box } from "@mui/system";
import { Chip } from "@mui/material";
const filter = createFilterOptions();

export default function Location({ region, setRegion, profile, tune }) {
  const [open, toggleOpen] = React.useState(false);

  const handleClose = () => {
    setDialogValue({
      name: "",
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    name: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setRegion({
      name: dialogValue.name,
    });
    handleClose();
  };

  const currentUser = Authentication.getCurrentUser();
  const [regions, setRegions] = React.useState([""]);
  React.useEffect(() => {
    ServerRequests.get_token("home/regions/", currentUser.auth_token).then(
      (response) => setRegions(response)
    );
  }, []);
  const handleRegionDelete = (uid) => {
    ServerRequests.delete_token(
      "tune/delete/region/" + uid + "/" + tune + "/",
      currentUser.auth_token
    ).then((response) => console.log(response));
  };

  return (
    <Box
      sx={{
        maxWidth: "500px",
        width: "100%",
        display: "block",
        margin: "auto",
        mt: 2,
      }}
    >
      <Autocomplete
        multiple
        id="tags-filled"
        limitTags={2}
        disableClearable={true}
        options={regions || []}
        defaultValue={region}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }

          return option.name;
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
  
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue.toLowerCase() === option.name.toLowerCase());
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              name: inputValue,
            });
          }
  
          return filtered;
        }}
        clearOnBlur
        onChange={(event, newValue, reason, detail) => {
          if (reason === "removeOption") {
            if (detail.option.uid != undefined) {
              handleRegionDelete(detail.option.uid);
            }
          }
          const finalValue = newValue.map((value) => {
            if (typeof value === "string") {
              return {inputValue: value, name: value};
            }
            return value;
          });
          const finalvalUnique = finalValue.filter(
            (v, i, a) => a.findIndex((t) => t.name === v.name) === i
          );
          setRegion(finalvalUnique);
        }}
        freeSolo
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option?.name || option}
              {...getTagProps({ index })}
              disabled={
                (option?.editor !== undefined) &
                (option?.editor !== profile?.id)
              }
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            label="Regions"
            placeholder="Click on the option"
          />
        )}
      />
    </Box>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
