import { Button, Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ServerRequests from "../../../../../Requests/Server";
import Authentication from "../../../../../Requests/UserData";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
const Materials = ({ mataus, setMataus }) => {
  const currentUser = Authentication.getCurrentUser();
  const [material, setMaterial] = React.useState(null);
  const [materials, setMaterials] = React.useState(null);
  const [counter, setCounter] = React.useState(0);
  React.useEffect(() => {
    ServerRequests.get_token("home/tom/", currentUser.auth_token).then(
      (result) => {
        setMaterials(result);
      }
    );
  }, []);
  const handleChange = (event) => {
    setMaterial({ ...material, type: event.target.value });
  };
  const handleAdd = () => {
    setMataus((prev) => [...prev, { ...material, id: counter }]);
    setCounter((prev) => prev + 1);
    setMaterial(null);
  };
  const handleDelete = (id) => {
    setMataus((prev) => prev.filter((item) => item.id !== id));
  };
  return (
    <Box>
      <Container>
        <Grid container>
          <Grid
            item
            md={8}
            sx={{
              borderRight: "1px solid #ddd",
            }}
          >
            <TextField
              id="standard-basic"
              value={material?.title || ""}
              sx={{ width: "100%", p: 2 }}
              onInput={(e) =>
                setMaterial({ ...material, title: e.target.value })
              }
              label="Title"
              variant="standard"
            />

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                sx={{ maxWidth: "300px" }}
                value={material?.type || ""}
                label="Type"
                onChange={handleChange}
              >
                <MenuItem value="" disabled={true}>
                  Default
                </MenuItem>
                {materials?.map((item) => (
                  <MenuItem value={item?.type}>{item?.type}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              id="standard-basic"
              sx={{ width: "100%", p: 2 }}
              value={material?.link || ""}
              onInput={(e) =>
                setMaterial({ ...material, link: e.target.value })
              }
              label="Link"
              variant="standard"
            />
            <Button
              variant="contained"
              sx={{ width: "100%" }}
              onClick={handleAdd}
            >
              Add Link
            </Button>
          </Grid>
          <Grid item md={4} sx={{ p: 1, background: "#eee" }}>
            <Typography sx={{ fontWeight: 900 }}>Preview</Typography>
            <Divider />
            <List sx={{ width: "100%", maxWidth: 360, bgcolor: "#eee" }}>
              {mataus?.map((item, key) => (
                <>
                  <ListItem alignItems="flex-start">
                    <IconButton
                      aria-label="Example"
                      onClick={(e) => handleDelete(item.id)}
                    >
                      <DeleteForeverIcon sx={{ color: "red" }} />
                    </IconButton>
                    <ListItemText
                      primary={item?.title}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {item?.type}
                          </Typography>
                          {" — " + item?.link}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </>
              ))}
            </List>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Materials;
