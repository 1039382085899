import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Authentication from "../../../../../Requests/UserData";
import ServerRequests from "../../../../../Requests/Server";
const View = ({ titles, setTitles, profile, tune }) => {
  const currentUser = Authentication.getCurrentUser();
  const handleDelete = (id, uid) => {
    if (uid != -1) {
      ServerRequests.delete_token(
        "tune/delete/title/" + uid + "/" + tune + "/",
        currentUser.auth_token
      ).then((response) => {
        if (response.ok === true) {
          setTitles((prev) => prev.filter((item) => item.id !== id));
        }
      });
    }
  };
  return (
    <List sx={{ width: "100%", bgcolor: "#eee" }}>
      {titles.map((title) => (
        <React.Fragment key={title?.id}>
          <ListItem alignItems="flex-start">
            <ListItemText primary={title?.title} />
            {title?.editor === profile?.id && (
              <IconButton
                aria-label="Example"
                onClick={(e) => handleDelete(title?.id, title?.uid)}
              >
                <DeleteForeverIcon sx={{ color: "red" }} />
              </IconButton>
            )}
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
};

export default View;
