import { CssBaseline } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Header from "../../Base/Header/Header";
import Sidebar from "../../Base/Sidebar/Sidebar";
import Filters from "../../Elements/MyTunes/Filters";
import TunesTable from "./TunesTable";
import ServerRequests from "../../../Requests/Server";
import Authentication from "../../../Requests/UserData";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Main = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  backgroundColor: "#eee",
}));
const MyTunes = () => {
  const currentUser = Authentication.getCurrentUser();
  const [player, setPlayer] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [tunes, setTunes] = React.useState(null);
  const [updated, setUpdated] = React.useState(0);
  const [filters, setFilters] = React.useState(null);

  const [competences, setCompetence] = React.useState(null);
  React.useEffect(() => {
    if (updated > 0) {
      ServerRequests.post_token(
        "home/mytunes/",
        currentUser.auth_token,
        filters
      )
        .then((result) => result.json())
        .then((response) => {
          setTunes(response);
        });
    } else {
      ServerRequests.get_token("home/mytunes/", currentUser.auth_token).then(
        (result) => {
          setTunes(result);
        }
      );
      ServerRequests.get_token("home/competence/", currentUser.auth_token).then(
        (result) => {
          setCompetence(result);
        }
      );
    }
  }, [updated]);

  const handleClose = () => {
    if (player !== null) setOpen(false);
  };

  const handleChose = () => {
    setPlayer({ naem: "hi" });
    setOpen(false);
  };

  const handleTitleSearch = (title) => {
    setFilters({ ...filters, title: title });
    setUpdated((prev) => prev + 1);
  };
  const handleResourceFilter = (resources) => {
    setFilters({ ...filters, resources: resources });
    setUpdated((prev) => prev + 1);
  };
  const handleSorting = (e) => {
    setFilters({ ...filters, sort: e });
    setUpdated((prev) => prev + 1);
  };
  return (
    <CssBaseline>
      <Container
        sx={{
          minHeight: "100vh",
          backgroundColor: "#eee",
        }}
        maxWidth={false}
        disableGutters
      >
        <Header />

        <Container maxWidth="xl" sx={{ flexGrow: 1, mt: 2 }}>
          <Grid container flexDirection="row" spacing={2}>
            <Grid
              item
              xs={2}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              <Sidebar />
            </Grid>
            <Grid item xs={12} md={10}>
              <Main>
                <Filters
                  handleTitleSearch={handleTitleSearch}
                  handleResourceFilter={handleResourceFilter}
                  handleSorting={handleSorting}
                  competences={competences}
                  setFilters={setFilters}
                  setUpdated={setUpdated}
                />
                {/* {tunes !== undefined && (
                  <TunesTable
                    competences={competences}
                    setUpdated={setUpdated}
                    tunes={tunes}
                  />
                )} */}
                Coming soon
              </Main>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </CssBaseline>
  );
};

export default MyTunes;
