import React from "react";
// import Box from "@mui/material/Box";
// import IconButton from "@mui/material/IconButton";
// import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
// import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
// import TextField from "@mui/material/TextField";
// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LoadingButton from "@mui/lab/LoadingButton";
import ServerRequests from "../../../../../Requests/Server";
import Authentication from "../../../../../Requests/UserData";
// import { Style } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[600],
    color: theme.palette.common.white,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 20,
  },
}));

const Title = ({ titles, setTitles, errors, setErrors }) => {
  const [addTitle, setAddTitle] = React.useState(null);
  const [clicked, setClicked] = React.useState(false);
  const [counter, setCounter] = React.useState(0);
  const [existingTitles, setExistingTitles] = React.useState([]);
  const currentUser = Authentication.getCurrentUser();

  const handleClickAddNewTitle = () => {
    if (addTitle !== null && addTitle !== "") {
      setTitles([
        ...titles,
        {
          id: counter,
          title: addTitle,
        },
      ]);
      setCounter((prev) => prev + 1);
      setClicked(true);
      setTimeout(() => {
        setClicked(false);
        setAddTitle("");
      }, 2000);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleInput = (e) => {
    const newTitle = e.target.value;
    setAddTitle(newTitle);

    if (newTitle !== null && newTitle.length > 2) {
      ServerRequests.post_token("home/tune/", currentUser.auth_token, {
        title: newTitle,
      })
        .then((result) => result.json())
        .then((response) => {
          if (response.length > 0) {
            response = response
              .map((item) => ({
                title: item.title
                  .map((subtitle) => subtitle.title)
                  .flat()
                  .join("; "),
                id: item.id,
              }))
              .flat();
            setExistingTitles((prev) => response);
          }
        });
    } else {
      setExistingTitles([]);
    }

    if (newTitle !== null && newTitle.length > 0) {
      setErrors({
        ...errors,
        titles: "Your title won't be saved until you click 'ADD TITLE' button.",
      });
    } else {
      setErrors({
        ...errors,
        titles: "",
      });
    }
  };

  return (
    <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
      <InputLabel htmlFor="outlined-adornment-password">
        Add new title
      </InputLabel>
      <FilledInput
        onInput={handleInput}
        value={addTitle}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            handleClickAddNewTitle();
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        disabled={clicked}
        variant="filled"
        endAdornment={
          <InputAdornment position="end">
            {clicked && <CheckCircleOutlineIcon sx={{ color: "green" }} />}
          </InputAdornment>
        }
        label="Add new Title"
      />
      <FormHelperText error={true} id="filled-weight-helper-text">
        {errors?.titles}
      </FormHelperText>

      {existingTitles.length > 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  Existing similar title - click the title to edit tune
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {existingTitles.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <a
                      style={{ textDecoration: "none", color: "black" }}
                      href={`/edit_tune/${row.id}`}
                    >
                      {row.title}
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <LoadingButton
        size="small"
        onClick={handleClickAddNewTitle}
        loading={clicked}
        loadingIndicator="Loading…"
        sx={{ mt: 1 }}
        variant="contained"
        onMouseDown={handleMouseDownPassword}
      >
        <span>ADD TITLE</span>
      </LoadingButton>
    </FormControl>
  );
};

export default Title;
