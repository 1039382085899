import * as React from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const Levels = ({ setFilters, level, setLevel, setUpdated }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Level</InputLabel>

      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="Level"
        defaultValue={level}
        onChange={(e) => {
          setFilters((prev) => ({
            ...prev,
            advanced: { ...prev?.advanced, level: e.target.value },
          }));
          setUpdated((prev) => prev + 1);
          setLevel(e.target.value);
        }}
      >
        <MenuItem value={0} selected>
          All
        </MenuItem>
        <MenuItem value={1}>Level 1</MenuItem>
        <MenuItem value={2}>Level 2</MenuItem>
        <MenuItem value={3}>Level 3</MenuItem>
        <MenuItem value={4}>Level 4</MenuItem>
        <MenuItem value={5}>Level 5</MenuItem>
      </Select>
    </FormControl>
  );
};

export default Levels;
