import React from "react";
import { CssBaseline } from "@mui/material";
import { Box, Container } from "@mui/system";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Draweror from "./Draweror";
import Choose from "./Choose";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const Filters = ({
  handleTitleSearch,
  handleResourceFilter,
  handleSorting,
  competences,
  setFilters,
  setUpdated,
}) => {
  const [resource, setResources] = React.useState("");

  const handleChange = (event) => {
    // setResources(event.target.value);
  };
  return (
    <Container
      maxWidth={false}
      sx={{ paddingLeft: "0 !important", paddingRight: "0 !important", mb: 1 }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Item>
            <Container>
              <Grid container spacing={1}>
                <Grid item md={12} sx={{ width: "100%" }}>
                  <Paper
                    component="form"
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search by titlee"
                      inputProps={{ "aria-label": "search by title" }}
                      onInput={(e) => handleTitleSearch(e.target.value)}
                    />
                    <IconButton
                      type="button"
                      sx={{ p: "10px" }}
                      aria-label="search"
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </Grid>
                <Grid container item md={12} sx={{ mt: 1 }}>
                  <Grid item md={2} sx={{ width: "100%" }}>
                    <Draweror setFilters={setFilters} setUpdated={setUpdated} />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="stretch"
                    item
                    md={10}
                  >
                    <Grid item>
                      <FormControl>
                        <FormLabel
                          sx={{
                            fontWeight: 800,
                          }}
                          id="demo-row-radio-buttons-group-label"
                        >
                          Resources
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          defaultValue="true"
                          onChange={(e) => handleResourceFilter(e.target.value)}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid item>
                      <FormControl fullWidth>
                        <FormLabel
                          sx={{
                            fontWeight: 800,
                          }}
                          id="demo-row-radio-buttons-group-label"
                        >
                          My Competence
                        </FormLabel>

                        <Autocomplete
                          multiple
                          id="tags-outlined"
                          limitTags={1}
                          options={competences || []}
                          onChange={(event, newValue) => {
                            setFilters((prev) => ({
                              ...prev,
                              competence: newValue,
                            }));
                            setUpdated((prev) => prev + 1);
                          }}
                          getOptionLabel={(option) => option?.title}
                          sx={{
                            maxWidth: 300,
                          }}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              sx={{
                                minWidth: 299,
                              }}
                              {...params}
                              placeholder="My Competence.."
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item>
                      <InputLabel
                        sx={{
                          fontWeight: 800,
                        }}
                        id="demo-simple-select-autowidth-label"
                      >
                        Tune type
                      </InputLabel>
                      <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={TuneTypes}
                        getOptionLabel={(option) => option?.title}
                        sx={{
                          maxWidth: 300,
                        }}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              minWidth: 299,
                            }}
                            {...params}
                            placeholder="Types.."
                          />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <FormControl sx={{ minWidth: 80 }}>
                        <FormLabel
                          sx={{
                            fontWeight: 800,
                          }}
                          id="demo-row-radio-buttons-group-label"
                        >
                          Sort records
                        </FormLabel>
                        <Select
                          displayEmpty
                          onChange={(e) => handleSorting(e.target.value)}
                          inputProps={{ "aria-label": "Without label" }}
                          defaultValue=""
                          autoWidth
                        >
                          <MenuItem value="">Default</MenuItem>

                          <MenuItem value="new">Newest first</MenuItem>
                          <MenuItem value="old">Old first</MenuItem>
                          <MenuItem value="az">By title (A to Z)</MenuItem>
                          <MenuItem value="za">By title (Z to A)</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Item>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Filters;
const TuneTypes = [
  { title: "Type 1" },
  { title: "Type 2" },
  { title: "Type 3" },
];
