import { CssBaseline, Link } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Logo from "../../../assets/logo/logo.png";

import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import ServerRequests from "../../../Requests/Server";
import Authentication from "../../../Requests/UserData";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import BottomMenu from "./BottomMenu";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0.8),
  textAlign: "center",
  color: theme.palette.text.secondary,
  borderRadius: 20,
}));
const Header = () => {
  const navigate = useNavigate();
  const currentUser = Authentication.getCurrentUser();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openp, setOpenp] = React.useState(false);
  const handleCloseBackdrop = () => {
    setOpenp(false);
  };
  const handleLogout = () => {
    ServerRequests.post_token("token/logout/", currentUser.auth_token).then(
      (response) => {
      }
    );
    setOpenp(true);
    setTimeout(() => {
      localStorage.clear();
      setOpenp(false);
      navigate("/authentication");
    }, 2000);
  };
  return (
    <Container maxWidth="xl" sx={{ flexGrow: 1, mt: 2 }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openp}
        onClick={handleCloseBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item md={2} xs={12} component={Link} href="/">
          <Item
            style={{
              backgroundImage: `url(${Logo})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",

              minHeight: "55px",
            }}
          ></Item>
        </Grid>
        <Grid
          item
          xs={12}
          md={10}
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          <Item>
            <Container>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
              >
                <Grid item>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    {/* <Typography>Dashboard</Typography> */}
                  </Box>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                      height: "100%",
                    }}
                  >
                    {/* <Typography sx={{ minWidth: 100 }}>Contact</Typography>
                    <Typography sx={{ minWidth: 100 }}>Profile</Typography> */}
                    <Tooltip title="Account settings">
                      <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      >
                        <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem component={Link} href="/profile">
                      <Avatar /> Profile
                    </MenuItem>
                    {/* <MenuItem>
                      <Avatar /> My account
                    </MenuItem> */}
                    <Divider />

                    <MenuItem onClick={handleLogout}>
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Container>
          </Item>
        </Grid>
      </Grid>
      <BottomMenu handleLogout={handleLogout} />
    </Container>
  );
};

export default Header;
