import React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Link } from "@mui/material";

const Verification = ({ uid, token }) => {
  const [activated, setActivated] = React.useState(null);

  const drop = async () => {
    const res = await fetch(
      global.config.infos.server_url + "users/activation/",
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          uid: uid,
          token: token,
        }),
      }
    );
    if (res.ok === true) {
      setActivated(true);
    } else {
      setActivated(false);
      console.log(22);
    }
  };
  React.useEffect(() => {
    drop();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box
      sx={{
        my: 8,
        mx: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {activated !== null && (
        <>
          {activated === false ? (
            <Alert
              variant="filled"
              icon={false}
              severity="error"
              sx={{
                textAlign: "center",
                p: 5,
              }}
            >
              <Typography variant="h4">Failed</Typography>
              <br />
              <Typography variant="subtitle1">
                Something wrong happened — <br />
                <strong> Try again later or check your email</strong>
                <Divider />
                <Link
                  href="/authentication"
                  sx={{
                    textDecoration: "none",
                    color: "white",
                  }}
                >
                  Login
                </Link>
              </Typography>
            </Alert>
          ) : (
            <Alert
              variant="filled"
              icon={false}
              severity="success"
              sx={{
                textAlign: "center",
                p: 5,
              }}
            >
              <Typography variant="h4">Success</Typography>
              <br />
              <Typography variant="subtitle1">
                We verified your email succsessfully — <br />
                <strong> You can login by clicking the button below :</strong>
                <Divider sc={{ mb: 2 }} />
                <Link
                  href="/authentication"
                  sx={{
                    textDecoration: "none",
                    color: "white",
                  }}
                >
                  Login
                </Link>
              </Typography>
            </Alert>
          )}
        </>
      )}
    </Box>
  );
};

export default Verification;
