import React from "react";
import { Autocomplete, InputLabel, TextField } from "@mui/material";
import { Box } from "@mui/system";
import ServerRequests from "../../../../../Requests/Server";
import Authentication from "../../../../../Requests/UserData";
import { set } from "date-fns";

import { createFilterOptions } from "@mui/material/Autocomplete";
const filter = createFilterOptions();

const Types = ({ types, setTypes, errors }) => {
  const currentUser = Authentication.getCurrentUser();

  const [type, setType] = React.useState(null);
  React.useEffect(() => {
    ServerRequests.get_token("home/types/", currentUser.auth_token).then(
      (result) => setType(result)
    );
  }, []);

  return (
    <Box sx={{ m: 1, width: "100%" }}>
      <Autocomplete
        multiple
        id="tags-filled"
        limitTags={2}
        options={type || []}
        defaultValue={types}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }

          return option.name;
        }}
        onChange={(event, newValue) => {
          const finalValue = newValue.map((value) => {
            if (typeof value === "string") {
              return {inputValue: value, name: value};
            }
            return value;
          });
          const finalvalUnique = finalValue.filter(
            (v, i, a) => a.findIndex((t) => t.name === v.name) === i
          );
          setTypes(finalvalUnique);
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
  
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue.toLowerCase() === option.name.toLowerCase());
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              name: inputValue,
            });
          }
  
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            label="Tune Types"
            error={errors?.types ? true : false}
            helperText={errors?.types}
            placeholder="Click on the option"
          />
        )}
      />
    </Box>
  );
};

export default Types;
