import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";

import ServerRequests from "../../../../../Requests/Server";
import Authentication from "../../../../../Requests/UserData";

const Level = ({ level, setLevel, profile, tune, levelsdata }) => {
  const currentUser = Authentication.getCurrentUser();
  const [levels, setLevels] = React.useState(null);
  React.useEffect(() => {
    ServerRequests.get_token("home/levels/", currentUser.auth_token).then(
      (result) => setLevels(result)
    );
  }, []);
  console.log(level);
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Level</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={level}
        defaultValue={level}
        label="Level"
        onChange={(e) => setLevel(e.target.value)}
      >
        {levels?.map((item) => (
          <MenuItem value={item?.id}>Level : {item?.name}</MenuItem>
        ))}
      </Select>
      <FormHelperText id="component-helper-text">
        Selected levels :{" "}
        {levelsdata?.map((item) => {
          if (item?.editor !== profile?.id) {
            return item?.level + " | ";
          }
        })}
      </FormHelperText>
    </FormControl>
  );
};

export default Level;
