import * as React from "react";
import FormControl from "@mui/material/FormControl";
import { Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import ServerRequests from "../../../../Requests/Server";
import Authentication from "../../../../Requests/UserData";
const Location = ({ setFilters, region, setRegion, setUpdated }) => {
  const currentUser = Authentication.getCurrentUser();
  const [country, setCountry] = React.useState("");
  const [countries, setCountries] = React.useState(null);
  const [regions, setRegions] = React.useState([""]);

  React.useEffect(() => {
    ServerRequests.post_token("home/regions/", currentUser.auth_token, {
      word: region,
    })
      .then((result) => result.json())
      .then((response) => setRegions(response));
  }, [region]);

  return (
    <FormControl fullWidth>
      <Typography>Region</Typography>

      <Autocomplete
        disablePortal
        id="combo-box-demo"
        value={region}
        inputValue={region}
        // defaultValue={region}

        onChange={(event, newValue) => {
          setFilters((prev) => ({
            ...prev,
            advanced: { ...prev?.advanced, region: newValue },
          }));
          setUpdated((prev) => prev + 1);
        }}
        onInputChange={(event, newInputValue) => {
          setRegion(newInputValue);
        }}
        options={regions || []}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} />}
      />
    </FormControl>
  );
};

export default Location;
