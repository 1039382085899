import {Link} from "@mui/material";
import React from "react";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import LinkIcon from '@mui/icons-material/Link';
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import GrainIcon from "@mui/icons-material/Grain";

const Item = styled(Paper)(({theme}) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  minHeight: "80vh",
  borderRadius: 20,
}));

const Sidebar = () => {
  return (
    <Item>
      <MenuList>
        {/* <MenuItem sx={{ whiteSpace: "break-spaces" }} component={Link} href="/">
          <ListItemIcon>
            <HomeIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={{ textAlign: "left" }}>Dashboard</ListItemText>
        </MenuItem>
        <Divider /> */}
        <MenuItem
          sx={{whiteSpace: "break-spaces"}}
          component={Link}
          href="/common_tunes"
        >
          <ListItemIcon>
            <GrainIcon fontSize="small"/>
          </ListItemIcon>
          <ListItemText sx={{textAlign: "left"}}>Common tunes</ListItemText>
        </MenuItem>
        {/* <Divider />
        <MenuItem
          sx={{ whiteSpace: "break-spaces" }}
          component={Link}
          href="/my_tunes"
        >
          <ListItemIcon>
            <Cloud fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={{ textAlign: "left" }}>My Tunes</ListItemText>
        </MenuItem>{" "} */}
        <Divider/>
        <MenuItem
          sx={{whiteSpace: "break-spaces"}}
          component={Link}
          href="/add_tune"
        >
          <ListItemIcon>
            <LibraryAddIcon fontSize="small"/>
          </ListItemIcon>
          <ListItemText sx={{textAlign: "left"}}>Add Tune</ListItemText>
        </MenuItem>
        <Divider/>
        <MenuItem
          sx={{whiteSpace: "break-spaces"}}
          component={Link}
          href="/links"
        >
          <ListItemIcon>
            <LinkIcon fontSize="small"/>
          </ListItemIcon>
          <ListItemText sx={{textAlign: "left"}}>Links</ListItemText>
        </MenuItem>
      </MenuList>
    </Item>
  );
};

export default Sidebar;
