import * as React from "react";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const Teacher = ({ teachers, students, handleChange }) => {
  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="grouped-native-select">Learned from</InputLabel>

      <Select
        native
        defaultValue=""
        id="grouped-native-select"
        label="Learned from"
        onChange={handleChange}
      >
        <option aria-label="All" value="" selected={true} />
        <optgroup label="Teachers">
          {teachers?.map((item) => (
            <option value={item.id}>
              {item.first_name} {item.last_name}
            </option>
          ))}
        </optgroup>
        <optgroup label="Students">
          {students?.map((item) => (
            <option value={item.id}>
              {item.first_name} {item.last_name}
            </option>
          ))}
        </optgroup>
      </Select>
    </FormControl>
  );
};

export default Teacher;
